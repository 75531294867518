@import '../../lib.scss';

.share {
  @include container;
  & {
    margin-top: 3rem;
  }

  &__heading {
    @include h4;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    @media screen and (min-width: $md) {
      margin-top: 0.5rem;
    }
  }

  &__link {
    display: block;
    line-height: 0;
    @include buttonFocus;
  }

  &__icon {
    svg {
      color: $ra-blue;
    }

    &:hover {
      svg {
        color: #155878;
      }
    }
  }
}
