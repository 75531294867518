@import '../../lib.scss';

.accordion-wrapper {
  margin-top: 5rem;
  &__container {
    @include container;
  }
  &__grid {
    @media screen and (min-width: $md) {
      @include grid;
    }
  }
  &__title {
    @include h2;
  }
  &__title,
  &__accordion {
    @media screen and (min-width: $md) {
      grid-column: 1 / span 8;
    }
  }
}
