@import '../../../lib.scss';

$light-bg: rgba(255, 255, 255, 0.6);
$dark-bg: rgba(42, 42, 42, 0.15);

.secondary-cta {
  @include ctaBase;
  display: inline-flex;
  font-size: 1rem;
  cursor: pointer;
  border: 3px solid $ra-black;
  background: transparent;
  padding: 0.5rem 2.3rem 0.5rem 1.3rem;
  border-top-left-radius: 14rem 7rem;
  border-bottom-left-radius: 10rem 9rem;
  border-top-right-radius: 16rem 7rem;
  border-bottom-right-radius: 17rem 9rem;
  line-height: 1.4rem;
  text-align: center;
  &__inner {
    display: flex;
    align-items: center;
  }
  .icon {
    position: absolute;
    right: 1rem;
    height: 1rem;
    width: 1rem;
    transition: right $transition-time;
  }
  &:hover {
    .icon {
      right: 0.5rem;
    }
  }
  &::before {
    content: '';
    transition: background-color $transition-time;
    background-color: rgba(0, 0, 0, 0);
    width: calc(100% + 0.5rem);
    height: 104%;
    position: absolute;
    display: block;
    top: -1%;
    left: -0.25rem;
    border-top-left-radius: 20rem 5rem;
    border-bottom-left-radius: 5rem 6rem;
    border-top-right-radius: 5rem 8rem;
    border-bottom-right-radius: 12rem 4rem;
    z-index: -1;
  }
  &:hover::before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  @include buttonFocus();
  &--dark {
    background-color: $ra-black;
    color: $ra-white;
    transition: background-color $transition-time;
    &:hover {
      background-color: $ra-blue;
      border-color: $ra-blue;
    }
    &:hover::before {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  &--white {
    color: $ra-white;
    border-color: $ra-white;
    transition: background-color $transition-time;
    &:hover {
      color: $ra-black;
      border-color: $ra-white;
    }
    &:hover::before {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
