@import '../../lib.scss';

.checkout {
  @include container;
  & {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: $ra-white;

    display: grid;
    gap: 2rem;
    padding-top: 2rem;

    @media screen and (min-width: $md) {
      grid-template-columns: 2fr 2fr;
      align-items: start;
      padding-top: 2rem;
    }
  }

  &__steps {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;

    span {
      line-height: 1.75rem;
      letter-spacing: -0.01em;
      color: $ra-black;

      &.active {
        font-weight: 500;
      }
    }
  }

  &__payment-error {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
    margin-bottom: 2rem;
    background: $ra-red;
    color: $ra-white;
    width: 100%;
  }

  &__main {
    padding: 2rem 1rem;
    width: 100%;
    position: relative;

    @media screen and (min-width: $sm) {
      padding: 2rem 1.5rem;
    }

    @media screen and (min-width: $md) {
      padding: 0 1rem;
      width: 100%;
    }
  }

  &__header {
    margin-bottom: 2rem;
  }

  &__title {
    @include h3;
    & {
      margin-bottom: 1rem;
      color: $ra-black;
    }
  }

  &__subtitle {
    @include h4;
    & {
      margin-bottom: 2rem;
      color: $ra-black;
    }
  }

  &__form {
    width: 100%;

    .form-field {
      margin-bottom: 1.5rem;

      &__label {
        display: block;
        font-weight: $bold;
        line-height: 1.75rem;
        color: $ra-black;
        margin-bottom: 0.5rem;
      }

      &__input {
        width: 100%;
        height: 44px;
        border: 1px solid $ra-black;
        border-radius: 18px;
        padding: 0.5rem 1rem;

        &:focus {
          outline: none;
          border-color: $ra-blue;
        }
      }

      &__help {
        line-height: 1.5rem;
        color: $summer-grey;
        margin-top: 0.25rem;
      }
    }
  }

  &__form-section {
    margin-bottom: 1.5rem;
    position: relative;

    &--postcode {
      margin-bottom: 2rem;
    }
  }

  &__phone-input {
    position: relative;

    .form-field__help {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      margin-top: 0;
      color: $summer-grey;
    }

    .form-field__label {
      padding-right: 4rem;
    }
  }

  &__address-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;

    @media screen and (min-width: $sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__link-button {
    @include linkInline;
    & {
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;

      @media screen and (min-width: $sm) {
        margin-left: auto;
      }
      & {
        color: $ra-blue;
      }
    }
  }

  &__delivery-toggle {
    margin: 2rem 0;
  }

  &__delivery-address {
    margin-top: 2rem;
    padding-top: 1rem;
  }

  &__address-fields {
    display: grid;
    gap: 2rem;
    margin-top: 1rem;

    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    .explorer-checkout__form-section {
      margin-bottom: 0;
    }
  }

  &__form-footer {
    margin-top: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__info,
  &__info-exhibit {
    display: flex;
    padding: 1rem;
    background-color: $ra-light-blue;
    margin-bottom: 1rem;
    @include body;
  }
  &__info-exhibit {
    background-color: $ra-light-blue;
  }

  &__info {
    background-color: $ra-light-blue;
  }

  &__terms {
    margin: 2rem 0;

    .checkbox {
      &__label {
        line-height: 1.75rem;
      }
    }
  }

  &__submit-button {
    margin-bottom: 2rem;
  }

  &__sidebar {
    background: $ra-lightest-grey;
    padding: 2rem;
    order: -1;

    @media screen and (min-width: $md) {
      order: initial;
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      overflow-y: auto;
      padding-bottom: 4rem;
    }
  }
  &__terms-error {
    color: $ra-red;
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}

.order-summary {
  &__desktop {
    display: none;

    @media screen and (min-width: $md) {
      display: block;
      padding-top: 5rem;
    }
  }

  &__mobile {
    display: block;
    background: $ra-lightest-grey;
    border-radius: 1rem;
    padding: 1rem;

    @media screen and (min-width: $md) {
      display: none;
    }

    .accordion-wrapper {
      margin: 0;
      background: transparent;
    }

    .accordion {
      border-bottom: none;

      &__button {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__panel {
        margin-top: 1rem;

        &--open {
          border-top: 1px solid $ra-light-grey;
        }
      }
    }
  }

  &__title {
    @include h4;
    & {
      color: $ra-black;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  &__item {
    display: flex;
    gap: 1rem;
    padding: 1.5rem 0;
    border-bottom: 1px solid $ra-light-grey;

    &:last-child {
      border-bottom: none;
    }
  }

  &__item-image {
    width: 3rem;
    height: 4rem;
    flex-shrink: 0;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  &__catalog-number {
    @include small;
    & {
      color: $ra-black;
      display: block;
      margin-bottom: 0.25rem;
    }
  }
  &__item-title {
    margin-bottom: 0.5rem;

    h3 {
      @include h5;
      & {
        color: $ra-black;
        margin-bottom: 0.25rem;
      }
    }

    p {
      @include body;
      & {
        color: $summer-grey;
      }
    }
  }

  &__item-type {
    @include small;
    & {
      color: $summer-grey;
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  &__price-info {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $ra-light-grey;

    dt,
    dd {
      @include body;
      & {
        line-height: 1.75rem;
        color: $ra-black;
      }
    }

    dd {
      text-align: right;
      margin: 0;

      &:last-child {
        font-weight: 700;
      }
    }
  }

  &__total-section {
    margin-top: 1.5rem;
  }

  &__total {
    @include body;
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 0.5rem;

      span {
        color: $ra-black;
        line-height: 1.75rem;

        &:last-child {
          text-align: right;
        }
      }

      &--final {
        margin-top: 0.75rem;

        span {
          font-weight: 700;
        }
      }
    }
  }
  .address-search {
    &__house-number {
      margin-bottom: 1.5rem;
    }

    &__postcode-section {
      margin-bottom: 2rem;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;

      @media screen and (min-width: $sm) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__manual-entry {
      @include linkInline;
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;
      color: $ra-blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
