@import '../../../lib.scss';

.image-card {
  cursor: pointer;

  &__image {
    // max-height: 440px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $ra-light-grey;
    padding-bottom: 1rem;
    // @media screen and (min-width: $md) {
    //   max-height: 460px;
    // }
  }

  &__caption {
    margin-top: 0.5rem;
  }

  &__title {
    display: inline;
    button {
      @include h5;
      & {
        border-bottom: 4px solid transparent;
      }
      &:focus-visible {
        @include linkFocus;
      }
    }
  }

  &__cta {
    text-align: left;
  }

  &__description {
    margin-top: calc(0.5rem - 4px);
    @include body;
  }

  &:hover {
    .image-card__cta {
      display: inline;
    }

    .image-card__cta-text {
      display: inline;
      border-bottom: 4px solid $ra-blue;
      color: $ra-blue;
    }
  }
}
