@import '../../lib.scss';

.featured-promo {
  margin-top: 5rem;
  &__container {
    @media screen and (min-width: $md) {
      @include container;
    }
  }

  &__grid {
    @media screen and (min-width: $sm) {
      display: grid;
      @include grid(12, 1fr);
      & {
        grid-template-rows: 160px 1fr 105px;
      }
    }
    @media screen and (min-width: $md) {
      @include grid(12, 1fr);
      & {
        grid-template-rows: 132px 1fr 16px;
      }
    }
    @media screen and (min-width: $lg) {
      @include grid(12, 1fr);
      & {
        grid-template-rows: 234px 1fr 16px;
      }
    }
  }

  &__image {
    grid-column: 1 / span 4;
    grid-row: 1 / span 3;
    position: relative;
    margin-right: 1rem;
    z-index: 0;
    aspect-ratio: 16/9;
    @media screen and (min-width: $sm) {
      grid-column: 1 / span 11;
      grid-row: 1 / span 3;
      margin-right: 0;
    }
    @media screen and (min-width: $md) {
      grid-column: 1 / span 8;
      grid-row: 1 / 3;
      margin-left: 0;
    }
    @media screen and (min-width: $lg) {
      grid-column: 1 / span 8;
      grid-row: 1 / 3;
    }
  }

  &__content {
    grid-column: 1 / 8;
    grid-row: 2 / 3;
    z-index: 1;
    padding: 1rem;
    padding-right: 2rem;
    margin-left: 1rem;
    margin-top: -1rem;
    position: relative;
    @media screen and (min-width: $sm) {
      margin-top: 0;
      grid-column: 2 / span 11;
      grid-row: 3 / span 2;
      margin-left: 0;
    }
    @media screen and (min-width: $md) {
      padding: 2rem;
      grid-column: 6 / span 7;
      grid-row: 2 / span 2;
      margin-right: 0;
    }
  }

  &__title {
    @include h2;
    a {
      color: inherit;
      text-decoration: none;
      &:hover > span {
        text-decoration: underline;
      }
      &:focus-visible {
        display: inline-flex;
        @include linkFocus;
        &:hover {
          border-bottom: none;
          color: $ra-black;
        }
      }
    }
  }
  &__subtitle {
    margin-top: 0.5rem;
    @include h5;
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $ra-black;
    padding: 0.25rem 0.5rem;
    @include body;
    & {
      color: $ra-white;
      line-height: 1rem;
      font-weight: bold;
    }
  }

  &__description,
  &__date {
    margin-top: 0.5rem;
  }

  &__cta {
    margin-top: 1rem;
  }
}
