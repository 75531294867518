@import '../../lib.scss';

.exhibition-hero {
  overflow-x: hidden;
  @media screen and (min-width: $md) {
    overflow-x: unset;
  }
  @media screen and (min-width: $xl) {
    margin-top: 2rem;
  }
  &__container {
    position: relative;
    z-index: 2;
    @include container;
  }

  &__image-container {
    position: relative;
    width: 100%;

    @media screen and (min-width: $md) {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      width: auto;
    }

    @media screen and (min-width: $lg) {
      margin-top: 1rem;
      margin-left: 1rem;
      width: auto;
    }

    @media screen and (min-width: $xl) {
      margin-top: 1rem;
      margin-left: 14.6rem;
      width: auto;
      max-width: 1332px;
    }
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: calc(100% + 2rem);
    grid-column: 1/-1;
    margin-left: -1rem;
    margin-right: -1rem;
    max-height: 590px;
    @media screen and (min-width: $sm) {
      aspect-ratio: 126/59;
      margin-right: 0rem;
      margin-left: -2rem;
    }

    @media screen and (min-width: $md) {
      width: calc(100% + 1.5rem);
      margin-left: -1.5rem;
    }

    @media screen and (min-width: $lg) {
      width: 100%;
      margin-left: -4rem;
    }

    & img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__video {
    background-color: #000;

    // force 16/9 aspect ratio
    height: 100%;
    width: 177.77777778vh;
    min-width: 100%;
    min-height: 56.25vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__video-btn-wrapper {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 1.5rem;
    right: 1rem;
    @media screen and (min-width: $sm) {
      bottom: 3rem;
      right: 3rem;
    }
    @media screen and (min-width: $md) {
      bottom: 4.5rem;
    }
  }

  &__video-btn {
    position: relative;
    background-color: rgba($ra-white, 0.7);
    width: 54px;
    height: 54px;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      background-color: $ra-white;
    }

    &:focus-visible {
      @include buttonFocus;
    }
  }

  &__video-btn-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__grid {
    position: relative;
    @include grid(4, 1fr);
    & {
      row-gap: 0;
      margin: 0 -0.5rem;
    }
    @media screen and (min-width: $sm) {
      @include grid(12, 1fr);
      row-gap: 0 !important;
      margin: initial;
    }
    @media screen and (min-width: $md) {
      margin: 0.5rem 0 0;
    }
    @media screen and (min-width: $lg) {
      margin: 1rem 0 0 0;
    }
  }

  &__promo {
    position: relative;
    grid-column: 1/5;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    margin-top: -1rem;
    background-color: $ra-white;

    @media screen and (min-width: $sm) {
      grid-column: 1/12;
      margin-left: -0.5rem;
      margin-right: -2rem;
    }

    @media screen and (min-width: $md) {
      margin-left: -1rem;
      padding: 1rem;
      margin-top: -3.5rem;
      margin-right: 0;
      grid-column: 1/9;
      grid-row: 1/3;
    }

    @media screen and (min-width: $lg) {
      margin-top: -4.5rem;
    }

    &__label {
      position: absolute;
      left: 0;
      right: auto;
      top: -1.5rem;
      background-color: $ra-black;
      color: $ra-white;
      padding: 0.25rem 1rem;
      font-size: 1rem;
      font-weight: $bold;
      line-height: 1rem;
    }

    &__title {
      @include h2;
    }

    &__subtitle {
      @include h5;
      & {
        margin-top: 0.5rem;
      }
    }

    &__date {
      display: flex;
      flex-direction: column;
      @include h5;
      & {
        margin-top: 0.5rem;
      }
      @media screen and (min-width: $md) {
        margin-top: 1rem;
      }
    }

    &__text {
      @media screen and (min-width: $md) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(2, auto);
        column-gap: 2rem;
      }
    }

    &__description {
      @include intro-text;
      & {
        margin-top: 1rem;
        visibility: visible;
      }
      @media screen and (min-width: $md) {
        grid-column: 1/8;
        grid-row: 1/2;
      }
    }

    &__children {
      img {
        margin-top: 1rem;
      }
      @media screen and (min-width: $md) {
        grid-column: 1/8;
        grid-row: 2/3;
      }
    }

    &__tags {
      display: flex;
      flex-flow: row wrap;
      margin-left: -0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      @media screen and (min-width: $sm) {
        margin-left: -1rem;
      }
    }

    &__tag {
      margin-top: 0.5rem;
      margin-left: 0.5rem;

      @media screen and (min-width: $sm) {
        margin-top: 1rem;
        margin-left: 1rem;
      }
    }

    &__tag-link {
      border-radius: 1rem;
      background-color: $ra-light-blue;
      padding: 0.25rem 1rem;
      color: $ra-blue;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        background-color: $ra-blue;
        color: $ra-light-blue;
        cursor: pointer;
      }
      &:focus-visible {
        @include buttonFocus;
      }
    }

    .rich-text {
      margin: 1rem 0 0 0;
      &__container {
        padding: 0;
      }
    }
  }

  &__right {
    @media screen and (min-width: $md) {
      position: sticky;
      top: 9rem;
      grid-column: 9/-1;
      display: block;
    }
  }

  &__info {
    position: relative;
    z-index: 1;
    background-color: $ra-light-blue;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 1rem;
    grid-column: 1/-1;
    padding: 0;
    @media screen and (min-width: $sm) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: calc(100% + 9.5rem);
      margin-left: -3rem;
      margin-top: 1rem;
    }

    @media screen and (min-width: $md) {
      display: none;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: calc(100% + 7rem);
      grid-column: 9/-1;
      margin-top: -3.5rem;

      margin-left: 0;
      margin-right: 0;
    }

    @media screen and (min-width: $lg) {
      margin-top: -4.5rem;
      width: calc(100% + 5rem);
    }
  }
  &__right &__linked-exhibition,
  &__right &__info {
    display: none;
    @media screen and (min-width: $md) {
      display: flex;
    }
  }
  &__right &__sponsor {
    display: none;
    @media screen and (min-width: $md) {
      display: block;
    }
  }
  &__tickets,
  &__shop {
    padding: 2rem 1rem 2rem 3rem;

    &--with-cta {
      cursor: pointer;
    }

    @media screen and (min-width: $sm) {
      padding: 2rem 1rem 2rem 5rem;
      flex-basis: 50%;
    }

    @media screen and (min-width: $md) {
      padding: 1rem 7rem 1.5rem 3rem;
      flex-basis: auto;
    }

    @media screen and (min-width: $lg) {
      padding: 2rem 7rem 2rem 4rem;
    }

    &__opening-hours {
      position: relative;

      &__days-and-times {
        font-size: 1.25rem;
        line-height: 1.75rem;
        @media screen and (min-width: $md) {
          font-size: 1.33rem;
          line-height: 1.77rem;
        }
      }
    }

    &__opening-hours + &__price {
      margin-top: 2rem;
    }

    &__price {
      position: relative;
      font-size: 1.25rem;
      line-height: 1.75rem;
      overflow-wrap: break-word;
      @media screen and (min-width: $md) {
        font-size: 1.33rem;
        line-height: 1.77rem;
      }
      em {
        @include body;
        & {
          display: block;
          font-weight: normal;
        }
      }
    }

    &__info {
      overflow-wrap: break-word;
    }

    &__icon {
      position: absolute;
      top: 7px;
      bottom: 0;
      left: -2rem;
      right: auto;

      &--calendar svg {
        width: 18px;
        height: 18px;
      }
    }

    &__status {
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: $bold;
      color: $ra-blue;
      margin-top: 0.5rem;

      @media screen and (min-width: $lg) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    &__cta {
      margin-top: 0.75rem;
    }
  }

  &__friends {
    cursor: pointer;
    padding: 0 1rem 2rem 3rem;

    @media screen and (min-width: $sm) {
      padding: 2rem 3.5rem 2rem 2rem;
      flex-basis: 50%;
    }

    @media screen and (min-width: $md) {
      padding: 0 7rem 1.5rem 3rem;
      flex-basis: auto;
    }

    @media screen and (min-width: $lg) {
      padding: 0 7rem 2rem 4rem;
    }

    &__title {
      position: relative;
      @include h5;
    }

    &__icon {
      position: absolute;
      top: 7px;
      bottom: 0;
      left: -2rem;
      right: auto;
    }
  }

  &__linked-exhibition {
    background-color: $ra-blue;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
    grid-column: 1/-1;
    padding: 1rem;

    @media screen and (min-width: $sm) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: calc(100% + 9.5rem);
      margin-left: -3rem;
      padding: 2rem 3rem 2rem;
    }

    @media screen and (min-width: $md) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: calc(100% + 7rem);
      padding: 2rem 2rem 2rem 1rem;
      padding-right: 7rem;

      margin-left: 0;
      margin-right: 0;
    }

    @media screen and (min-width: $lg) {
      width: calc(100% + 5rem);
      padding-left: 2rem;
    }

    &__title {
      color: $ra-white;
      @include h5;
    }

    &__cta {
      display: flex;
      margin-top: 0.25rem;

      @media screen and (min-width: $md) {
        margin-top: 0.5rem;
      }
    }
  }

  &__sponsor {
    grid-column: 1/5;
    padding: 0.5rem;
    background-color: $ra-white;
    padding-top: 2rem;
    @media screen and (min-width: $sm) {
      padding-left: 0;
    }

    @media screen and (min-width: $md) {
      display: none;
      padding: 1rem;
    }

    @media screen and (min-width: $lg) {
      padding: 2rem;
    }

    &__title {
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: $bold;
      margin-bottom: 1rem;
    }

    &__logo {
      max-width: 100%;
      max-height: 7rem;
      margin-bottom: 1rem;

      img {
        max-height: 7rem;
        width: auto;
        max-width: 100%;
      }
    }

    &__link {
      @include linkInline;
      &:hover {
        cursor: pointer;
      }

      &:focus-visible {
        @include linkFocus;
      }
    }
  }
  .rich-text--grid .rich-text__wrapper {
    grid-column: 1 / span 12;
    @media screen and (min-width: $md) {
      grid-column: 1 / span 12;
    }
  }
}
