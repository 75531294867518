@import '../../../lib.scss';

.explorer-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 18rem;
  max-width: 18rem;
  height: 100%;
  transition: transform $transition-time;

  &:hover {
    transform: translateY(-0.4rem);
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 133.33%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__free {
    font-weight: bold;
    padding: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $ra-white;
    color: $ra-blue;
    z-index: 1;
  }

  &__label {
    @include body;
    & {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $ra-black;
      padding: 0.25rem 0.5rem;
      color: $ra-white;
      line-height: 1rem;
      font-weight: bold;
      z-index: 1;
    }
    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__title {
    @include h4;
    & {
      margin-top: 0.75rem;
    }
  }

  &__link {
    color: $ra-black;
    text-decoration: none;
    display: inline;
    line-height: 1.5rem;
    @media screen and (min-width: $md) {
      line-height: 1.7rem;
    }
    &:hover {
      border-bottom: 4px solid $ra-blue;
      color: $ra-blue;
    }
    &:focus-visible {
      display: inline-flex;
      @include linkFocus;
      &:hover {
        border-bottom: none;
        color: $ra-black;
      }
    }
  }

  &__subtitle {
    @include h5;
    & {
      margin-top: 0.5rem;
      color: $ra-black;
    }
  }

  &__date,
  &__status,
  &__time {
    @include body;
    & {
      margin-top: 0.25rem;
      color: $ra-black;
    }
  }

  &__content {
    padding-bottom: 0.5rem;
    margin-bottom: auto;
  }

  &__status {
    font-weight: $bold;
  }
}
