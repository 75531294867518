@import '../../../lib.scss';

.search-dropdown {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  @media screen and (min-width: $md) {
    height: initial;
  }
  &__header {
    background-color: $ra-black;
    @media screen and (min-width: $md) {
      height: $desktop-nav-height;
    }
    @media screen and (max-width: $md) {
      padding-top: $mobile-nav-height;
      // Hack to hide scrolling text beneath the 'back' button on mobile
      transform: scaleY(1.05);
    }
  }
  &__container {
    @media screen and (min-width: $md) {
      @include container();
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    height: 100%;
  }
  &__grid {
    @media screen and (min-width: $md) {
      @include grid(12, 1fr);
    }
  }
  &__form {
    padding: 3rem 1rem;
    grid-column: 4 / span 6;
    @media screen and (min-width: $md) {
      padding: 3rem 0rem;
    }
  }
  &__title {
    @include h3;
    & {
      margin-bottom: 2rem;
    }
  }
  &__collection-link a {
    text-decoration: none;
    color: $ra-blue;
    @include linkInline;
    &:focus-visible {
      @include linkFocus;
    }
  }
  .form-field {
    margin-bottom: 2rem;
  }
  .form-field__input {
    border-radius: 1rem;
    &::placeholder {
      color: $ra-black;
    }
  }
  .form-field--inline-button button {
    border-radius: 0 1rem 1rem 0;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(42, 42, 42, 0.4);
    position: fixed;
    top: $desktop-nav-height;
    left: 0;
  }
  &__close {
    background-color: transparent;
    color: $ra-white;
    padding: 2rem 3rem;
    @include h5;
    & {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    svg {
      margin-right: 1rem;
    }
    @media screen and (min-width: $md) {
      position: absolute;
      padding: 0;
      right: 2.5rem;
      top: 2.5rem;
      @include buttonFocus;
      svg {
        margin-right: 0;
      }
    }
  }

  p a {
    color: $ra-blue;
    text-decoration: none;
    @include linkInline;
    &:focus-visible {
      @include linkFocus;
    }
  }
}
