@import '../../lib.scss';

.friend-signup {
  margin-top: 3rem;
  @media screen and (min-width: $md) {
    margin-top: 4.5rem;
  }
  &__container {
    @include container;
    & {
      padding-top: 3rem;
      padding-bottom: 2rem;
    }
    // Override container no padding styling
    @media screen and (min-width: $sm) {
      padding-top: 3rem;
      padding-bottom: 2rem;
    }
    @media screen and (min-width: $md) {
      padding-top: 4.5rem;
    }

    // Theme switching
    &.theme--ra-white {
      .friend-signup__gift-control__outer {
        background-color: $ra-light-blue;
      }

      .friend-signup__gift-control__option {
        label {
          background: $ra-light-blue;
        }
        input:checked ~ label {
          background-color: $ra-blue;
        }
      }
    }

    &.theme--ra-green,
    &.theme--ra-light-green {
      .friend-signup__gift-control__option {
        label {
          background: white;
          color: $ra-green;
        }
        input:checked ~ label {
          background-color: $ra-green;
          color: $ra-light-green;
        }
      }
      .radio {
        label {
          border-color: $ra-green;
        }

        &__input {
          border-color: $ra-green;
          &:checked + label {
            background: $ra-light-green;
            border-color: $ra-light-green;
          }
        }
      }
      .date-picker__day--highlight span {
        background-color: $ra-green;
      }
      .payment-method__additional-information button {
        color: $ra-green;
        @media screen and (min-width: $md) {
          color: $ra-white;
          background-color: $ra-green;
        }
      }
      .payment-method__more-info {
        color: $ra-green;
      }
    }

    &.theme--ra-pink,
    &.theme--ra-light-pink {
      .friend-signup__gift-control__option {
        label {
          background: white;
          color: $ra-pink;
        }
        input:checked ~ label {
          background-color: $ra-pink;
          color: $ra-light-pink;
        }
      }
      .radio {
        label {
          border-color: $ra-pink;
        }

        &__input {
          border-color: $ra-pink;
          &:checked + label {
            background: $ra-light-pink;
            border-color: $ra-light-pink;
          }
        }
      }
      .date-picker__day--highlight span {
        background-color: $ra-pink;
      }
      .payment-method__additional-information button {
        color: $ra-pink;
        @media screen and (min-width: $md) {
          color: $ra-white;
          background-color: $ra-pink;
        }
      }
      .payment-method__more-info {
        color: $ra-pink;
      }
    }

    &.theme--ra-purple,
    &.theme--ra-light-purple {
      .friend-signup__gift-control__option {
        label {
          background: white;
          color: $ra-purple;
        }
        input:checked ~ label {
          background-color: $ra-purple;
          color: $ra-light-purple;
        }
      }
      .radio {
        label {
          border-color: $ra-purple;
        }

        &__input {
          border-color: $ra-purple;
          &:checked + label {
            background: $ra-light-purple;
            border-color: $ra-light-purple;
          }
        }
      }
      .date-picker__day--highlight span {
        background-color: $ra-purple;
      }
      .payment-method__additional-information button {
        color: $ra-purple;
        @media screen and (min-width: $md) {
          color: $ra-white;
          background-color: $ra-purple;
        }
      }
      .payment-method__more-info {
        color: $ra-purple;
      }
    }
  }
  &__title {
    @include h2;
    @media screen and (min-width: $md) {
      text-align: center;
    }
  }
  &__gift-control {
    margin-top: 2rem;
    @media screen and (min-width: $md) {
      display: flex;
      justify-content: center;
    }
    @media screen and (min-width: $lg) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2rem;
    }
    &__outer {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      background: white;
      border-radius: 2rem;
      padding: 0.25rem;
      @media screen and (min-width: $md) {
        display: inline-grid;
      }
      @media screen and (min-width: $lg) {
        grid-column: 2 / 3;
      }
    }

    input {
      @include sr-only;
    }
    &__option {
      label {
        display: block;
        background: white;
        padding: 0.5rem 1.5rem;
        border-radius: 3rem;
        text-align: center;
        @include h5;
        & {
          font-weight: $bold;
          color: $ra-blue;
          cursor: pointer;
        }
      }
      input:checked ~ label {
        background-color: $ra-blue;
        color: $ra-light-blue;
      }
      input:focus ~ label {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 0 3px $ra-black, 0 0 0 6px $ra-yellow;
        transition: box-shadow $transition-time;
      }
    }
  }
  &__memberships {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $md) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2rem;
      margin-top: 3.5rem;
    }
  }
  &__dropdown {
    position: relative;
    z-index: 1;
    background: $ra-white;
    margin-top: 1.5rem;
    @media screen and (min-width: $md) {
      margin-top: 2rem;
      padding: 2rem 3rem;
      &::after {
        content: '';
        width: 2rem;
        height: 2rem;
        background-color: $ra-white;
        top: -1rem;
        left: 15%;
        transform: rotate(45deg);
        display: block;
        position: absolute;
      }

      &--2,
      &--5 {
        &::after {
          left: 48.5%;
        }
      }

      &--3,
      &--6 {
        &::after {
          left: auto;
          right: 15%;
        }
      }
    }
    &__grid {
      position: relative;
      z-index: 2;
      @media screen and (min-width: $md) {
        @include grid(12, 1fr);
      }
    }
    &__components {
      background-color: $ra-white;
      grid-column: 1 / span 8;
      color: $ra-black;
      @media screen and (min-width: $md) {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 3rem;
      }
    }
    .membership-summary {
      grid-column: 9 / span 4;
    }
    .radio {
      &__input {
        appearance: none;
        background-color: $ra-white;
        border: 1px solid $ra-blue;
        border-radius: 50%;
        margin: 0;
        width: 1.5rem;
        height: 1.5rem;
        box-sizing: border-box;

        &:checked {
          border-width: 0.4rem;
        }
      }
      &__label {
        border-radius: 1.25rem;
        @media screen and (min-width: $md) {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
  }

  &__desc-wrapper {
    @include intro-text;
    & {
      margin-top: 1rem;
    }
    @media screen and (min-width: $md) {
      margin-top: 2rem;
      @include grid(12);
      & {
        text-align: center;
        padding-bottom: 2rem;
      }
    }
  }

  &__desc {
    @media screen and (min-width: $md) {
      grid-column: 4 / span 6;
    }
  }

  .form-field__help {
    a {
      &:focus-visible {
        @include linkFocus;
      }
    }
  }

  &-container {
    margin-top: 1.5rem;
  }

  .error-message,
  .error-message--mobile {
    margin: 0.25rem 0;
    color: $ra-red;
  }

  @media screen and (min-width: $md) {
    .error-message--mobile {
      display: none;
    }
  }
}
