#CybotCookiebotDialogPoweredbyCybot,
.CybotCookiebotDialogArrow,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogNav {
  display: none !important;
}
#CybotCookiebotDialogHeader {
  padding: 0.5rem !important;
}
#CybotCookiebotDialogPoweredbyImage {
  max-height: 4em !important;
}
