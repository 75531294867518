@import '../../../lib.scss';

.gift-recipient {
  padding: 1rem;
  @media screen and (min-width: $md) {
    padding: 0;
  }
  &__title {
    @include h4;
  }
  &__desc {
    margin-top: 1rem;
  }
  &__form {
    &__row {
      margin-top: 1rem;
      &--no-margin {
        margin: 0;
        &.form-field__error {
          // margin-top: -1rem;
        }
      }
      &--inline {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        @media screen and (min-width: $md) {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          .dropdown {
            grid-column: 1 / span 2;
            select {
              width: 25%;
              display: block;
            }
          }
        }
      }
    }
  }
  &__subtitle {
    @include h4;
    & {
      margin-top: 2rem;
    }
  }
}
