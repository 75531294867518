@import '../../../lib.scss';

.explorer-navigation {
  @media screen and (max-width: $md) {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__container {
      width: 100%;
    }

    &__links {
      display: flex;
      flex-direction: column;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__link {
      width: 100%;
      border-bottom: 1px solid rgba($ra-white, 0.1);

      &:last-child {
        border-bottom: none;
      }

      button {
        @include h5;
        background-color: transparent;
        color: $ra-white;
        display: flex;
        font-weight: bold;
        text-decoration: none;
        padding: 1.5rem 2rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;

        &:hover {
          text-decoration: underline;
        }

        svg {
          margin-left: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: $md) {
    padding: 0;
    width: 100%;

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__links {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-self: flex-end;
      list-style: none;
      margin: 0;
      background-color: transparent;
    }

    &__link {
      margin-right: 5rem;

      &:last-of-type {
        margin-right: 0;
      }

      > button {
        background-color: transparent;
        color: $ra-white;
        @include h6;
        font-size: 1.1rem;
        @include underline-link;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        border: none;
        padding: 0;

        &:focus-visible {
          @include linkFocus;
        }

        .icon--underline {
          bottom: -0.5rem;
        }
      }

      &--open > button svg {
        display: block;
      }
    }
  }

  @media screen and (min-width: $xl) {
    &__links {
      justify-content: center;
    }
  }
}
