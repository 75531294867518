@import '../../../lib.scss';

@keyframes ra-line {
  to {
    stroke-dashoffset: 0;
  }
}

.tertiary-cta {
  @include ctaBase;
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all transition-time;
  margin-right: 1.625rem;
  cursor: pointer;
  @media screen and (min-width: $sm) {
    align-items: center;
  }
  &:hover {
    color: $ra-blue;
    svg {
      fill: $ra-blue;
    }
    .tertiary-cta__arrow {
      right: -2.5rem;
      @media screen and (min-width: $sm) {
        right: -2.25rem;
      }
    }
  }
  &:focus-visible {
    .tertiary-cta__line-wrapper {
      display: none;
    }
    .tertiary-cta__arrow {
      fill: $ra-black;
    }
  }

  &__text {
    position: relative;
    color: inherit;
    padding-bottom: 0.25rem;
    text-align: left;
    @media screen and (min-width: $sm) {
      text-align: center;
    }
  }

  &__arrow {
    position: absolute;
    top: 0.2rem;
    right: -2rem;
    fill: $ra-black;
    transition: right $transition-time;
    @media screen and (min-width: $sm) {
      right: -1.75rem;
    }
  }

  &__line-wrapper {
    position: absolute;
    max-height: 4px;
    max-width: 90%;
    min-width: 90%;
    margin: 0 auto;
    bottom: 0;
  }

  &__line {
    &--animate {
      stroke: $ra-black;
      stroke-dasharray: 300;
      stroke-dashoffset: 300;
      animation: ra-line 0.5s ease-out forwards;
    }
  }

  &:focus-visible {
    @include linkFocus;
  }

  &--theme--ra-blue,
  &--theme--ra-pink,
  &--theme--ra-purple,
  &--theme--ra-green,
  &--theme--ra-black {
    color: $ra-white;
    transition: all $transition-time;
    svg {
      fill: $ra-white;
    }
  }

  &--theme--ra-blue {
    &:hover {
      color: $ra-light-blue;
      svg {
        fill: $ra-light-blue;
      }
    }
  }
  &--theme--ra-pink {
    &:hover {
      color: $ra-light-pink;
      svg {
        fill: $ra-light-pink;
      }
    }
  }
  &--theme--ra-purple {
    &:hover {
      color: $ra-light-purple;
      svg {
        fill: $ra-light-purple;
      }
    }
  }
  &--theme--ra-green {
    &:hover {
      color: $ra-light-green;
      svg {
        fill: $ra-light-green;
      }
    }
  }
  &--theme--ra-black {
    transition: all $transition-time;
    &:hover {
      color: $ra-light-blue;
      svg {
        fill: $ra-light-blue;
      }
    }
  }

  &--theme--ra-light-blue {
    transition: all $transition-time;
    &:hover {
      color: $ra-blue;
      svg {
        fill: $ra-blue;
      }
    }
  }

  &--theme--ra-light-pink {
    transition: all $transition-time;
    &:hover {
      color: $ra-pink;
      svg {
        fill: $ra-pink;
      }
    }
  }

  &--theme--ra-light-purple {
    transition: all $transition-time;
    &:hover {
      color: $ra-purple;
      svg {
        fill: $ra-purple;
      }
    }
  }

  &--theme--ra-light-green {
    transition: all $transition-time;
    &:hover {
      color: $ra-green;
      svg {
        fill: $ra-green;
      }
    }
  }

  &--theme--ra-white {
    transition: all $transition-time;
    color: $ra-white;
    transition: all $transition-time;
    svg {
      fill: $ra-white;
    }

    &:hover {
      color: $ra-white;
      svg {
        fill: $ra-white;
      }
    }
  }
}
