/*************************
** Breakpoints **
*************************/
$sm: 768px;
$md: 1024px;
$lg: 1440px;
$xl: 1920px;

/*************************
** Colours **
*************************/
$ra-black: #2a2a2a;
$ra-blue: #217096;
$ra-light-blue: #d4edf5;
$ra-dark-blue: #003d4c;
$ra-green: #00766b;
$ra-light-green: #e1eddf;
$ra-purple: #532e60;
$ra-light-purple: #d2c7e0;
$ra-pink: #c6156e;
$ra-light-pink: #f3e2e4;
$ra-light-grey: #dfdfdf;
$ra-white: #ffffff;
$ra-yellow: #f0db3d;
$ra-red: #e90258;
$ra-lightest-grey: #f5f5f5;
$summer-grey: #626262;

$transition-time: 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67);

/*************************
** Typography **
*************************/

$font: 'akzidenz-grotesk', sans-serif;
$intro-font: 'adobe-caslon-pro', serif;
$normal: 400;
$bold: 700;

@mixin h1 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: 2.25rem;
  line-height: 2.25rem;
  letter-spacing: -0.01em;
  @media screen and (min-width: $md) {
    font-size: 3.4rem;
    line-height: 3.3rem;
  }
}

@mixin h2 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
  @media screen and (min-width: $md) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}
@mixin h3 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
  @media screen and (min-width: $md) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}
@mixin h4 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: -0.01em;
  @media screen and (min-width: $md) {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
}
@mixin h5 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.01em;
  @media screen and (min-width: $md) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
}
@mixin h6 {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.01em;
  @media screen and (min-width: $md) {
    line-height: 1.5rem;
  }
}

@mixin body {
  font-family: $font;
  font-style: normal;
  font-weight: $normal;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;

  // Prevent body from scrolling when mobile nav is open
  &.lock-scroll {
    overflow: hidden;
    @media screen and (min-width: $md) {
      overflow: visible;
    }
  }
}

@mixin intro-text {
  font-family: $intro-font;
  font-style: normal;
  font-weight: $normal;
  font-size: 1.25rem;
  line-height: 1.625rem;
  @media screen and (min-width: $sm) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@mixin body-text-sans-serif {
  font-family: $intro-font;
  font-style: normal;
  font-weight: $normal;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

@mixin small {
  font-family: $font;
  font-style: normal;
  font-weight: $normal;
  font-size: 0.9rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
}

@mixin underline-link {
  position: relative;
  text-decoration: none;
  svg {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
  }
  &:hover {
    svg {
      display: block;
    }
  }
  &:focus {
    svg {
      display: none;
    }
  }
}

/*************************
** Grid **
*************************/
$width: 1272px;
$cols: 12;

@mixin container($width: $width) {
  max-width: $width;
  margin: 0 auto;
  padding: 0 1rem;
  @media screen and (min-width: $sm) {
    padding: 0 2rem;
    max-width: calc($width + 4rem);
  }
  @media screen and (min-width: $lg) {
    padding: 0 5rem;
    max-width: calc($width + 10rem);
  }
}

@mixin grid($cols: $cols, $size: 1fr) {
  display: grid;
  grid-template-columns: repeat($cols, $size);
  gap: 1rem;
  @media screen and (min-width: $sm) {
    gap: 2rem;
  }
}

/*************************
** Navigation **
*************************/

$mobile-nav-height: 76px;
$desktop-nav-height: 137px;
$scrolling-nav-height: 83px;
$scrolling-nav-explorer: 105px;

/*************************
** Accessibility **
*************************/
@mixin sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  font-size: 1rem;
}

@mixin linkFocus {
  display: inline-flex;
  background: linear-gradient(to left, transparent 50%, $ra-yellow 50%) right;
  background-size: 210% 100%;
  color: $ra-black;
  background-position: left;
  transition: background-position $transition-time;
  box-shadow: 0px 6px $ra-black;
}

@mixin linkHotspot {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin linkInline {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  &:hover {
    border-bottom: 4px solid;
    margin-bottom: -3px;
  }
}

@mixin ctaBase {
  position: relative;
  display: inline-block;
  font-family: $font, sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: $bold;
  color: $ra-black;
  z-index: 1;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

@mixin buttonFocus($cta: false, $el: 'after') {
  @if $cta {
    &:focus-visible .cta__text::#{$el} {
      box-shadow: 0 0 0 3px $ra-black, 0 0 0 6px $ra-yellow;
      transition: box-shadow $transition-time;
    }
  } @else {
    &:focus-visible {
      box-shadow: 0 0 0 3px $ra-black, 0 0 0 6px $ra-yellow;
      transition: box-shadow $transition-time;
    }
  }
}

/*************************
** Theme **
*************************/

@mixin theme {
  &.theme--ra-blue {
    background-color: $ra-blue;
    color: $ra-white;
  }
  &.theme--ra-purple {
    background-color: $ra-purple;
    color: $ra-white;
  }
  &.theme--ra-pink {
    background-color: $ra-pink;
    color: $ra-white;
  }
  &.theme--ra-green {
    background-color: $ra-green;
    color: $ra-white;
  }
  &.theme--ra-light-blue {
    background-color: $ra-light-blue;
    color: $ra-black;
  }
  &.theme--ra-light-purple {
    background-color: $ra-light-purple;
    color: $ra-black;
  }
  &.theme--ra-light-pink {
    background-color: $ra-light-pink;
    color: $ra-black;
  }
  &.theme--ra-light-green {
    background-color: $ra-light-green;
    color: $ra-black;
  }
}
