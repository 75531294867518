@import '../../lib.scss';

.header {
  &__logo {
    a {
      display: inline-block;
      @include buttonFocus;
    }
  }
  @media screen and (max-width: ($md - 1px)) {
    padding-top: $mobile-nav-height;
    &__container {
      position: fixed;
      top: 0;
      left: -100vw;
      height: 100%;
      width: 100%;
      z-index: 7;
      padding: 1rem;
      padding-top: $mobile-nav-height;
      transition: left 0.2s ease-in-out;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
    &__grid {
      display: flex;
      flex-direction: column-reverse;
    }
    .basket {
      left: 200vw;
      display: block;
    }
    .search-dropdown {
      left: 200vw;
      display: block;
    }
    .primary-navigation__subnav {
      left: 200vw;
      display: block;
    }
    &--open {
      .header__container,
      .user-menu__basket-handle--open .basket,
      .user-menu__search-handle--open .search-dropdown,
      .primary-navigation__subnav--open {
        left: 0;
      }
    }

    &__mobile {
      padding: 1rem 1rem 0.6rem;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 76px;
      overflow: hidden;
    }
    &__logo {
      svg {
        height: 44px;
        width: auto;
      }
    }
    &__menu-toggle {
      button {
        color: inherit;
        background: transparent;
        cursor: pointer;
      }
    }
    ul {
      list-style: none;
    }
  }

  @media screen and (min-width: $md) {
    padding-top: $desktop-nav-height;
    &__container {
      padding: 1.5rem 0 0 1rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: $desktop-nav-height;
      z-index: 9;
      transition: all 0.2s;
    }
    &__logo {
      position: relative;
      z-index: 1;
      svg {
        height: 84px;
        transition: all 0.2s;
      }
    }
    .user-menu__navigation,
    .user-menu__friend {
      transition: all 0.2s;
    }
    &--scrolling .header__container {
      height: $scrolling-nav-height;
      padding-top: 0px;
      padding-bottom: 0px;
      .user-menu {
        top: 0.25rem;
        &__navigation {
          margin-top: -2rem;
        }
        &__friend {
          margin: 0.25rem 1rem;
        }
      }
      .header__logo {
        padding: 1rem 0 0.5rem;
        z-index: 10;
        svg {
          height: 49px;
          width: auto;
        }
      }
      .search-dropdown__overlay,
      .basket__overlay,
      .primary-navigation__subnav__overlay {
        top: $scrolling-nav-height;
      }
    }
    &__logo {
      padding-bottom: 1rem;
      float: left;
      z-index: 10;
    }
    .primary-navigation {
      position: absolute;
      bottom: 0;
      left: 0;
      background: transparent;
    }
    .user-menu {
      position: absolute;
      top: 0.5rem;
      right: 0;
      background: transparent;
    }
  }
}
