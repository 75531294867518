@import '../../../lib';

.explorer-basket-footer {
  box-sizing: border-box;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 90rem;
  height: 5.75rem;
  background-color: $ra-white;
  border-top: 1px solid $ra-black;
  z-index: 3;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    gap: 25rem;
    width: 100%;
    height: 100%;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 1rem;
    width: 78rem;
    height: 1.75rem;
  }

  &__item {
    @include body;
    & {
      color: $ra-black;
      line-height: 1.75rem;
      letter-spacing: -0.01em;
      text-decoration: none;
    }

    &--copyright {
      width: 4.75rem;
    }

    &--terms {
      width: 9rem;
    }

    &--privacy {
      width: 3.25rem;
    }

    &--cookies {
      width: 4rem;
    }

    &--slavery {
      width: 12.5rem;
    }

    &--accessibility {
      width: 11rem;
    }

    &:not(&--copyright):hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 90rem) {
  .explorer-basket-footer {
    width: 100%;
    position: relative;

    &__nav {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.25rem;
    }
  }
}
