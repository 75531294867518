@import '../../../lib.scss';

.whats-on-listing {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__item {
    display: flex;
  }
}
