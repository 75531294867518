@import '../../lib.scss';

.sponsors {
  @include container;
  & {
    margin-top: 3rem;
  }
  + .sponsors {
    margin-top: 0;
  }
  @media screen and (min-width: $md) {
    @include grid(12);
  }

  &__grid {
    grid-column: 1 / span 8;
  }

  &__lists {
    display: flex;
    flex-direction: column;
    gap: 1.625rem;

    @media screen and (min-width: $sm) {
      gap: 2rem;
    }

    @media screen and (min-width: $md) {
      gap: 3rem;
    }

    &--stacked {
      @media screen and (min-width: $sm) {
        display: grid;
        @include grid(12);
      }

      @media screen and (min-width: $md) {
        display: grid;
        @include grid(8);
      }
    }
  }

  &__heading {
    @include h2;
  }

  &__main {
    margin-top: 1rem;

    &--stacked {
      @media screen and (min-width: $sm) {
        grid-column: 1 / span 6;
      }

      @media screen and (min-width: $md) {
        grid-column: 1 / span 4;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: flex-start;
      }

      .sponsors__main-item {
        padding: 0;

        @media screen and (min-width: $lg) {
          max-width: 16.5rem;
        }
      }
    }
  }

  &__main-list,
  &__additional-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media screen and (min-width: $sm) {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 2rem;
      align-items: top;
    }

    @media screen and (min-width: $md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 1.5rem;
      column-gap: 0;
    }

    @media screen and (min-width: $lg) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 1.5rem;
    }
  }

  &__main-item {
    max-width: 21rem;
    img {
      max-width: 100%;
      width: auto;
      height: auto;
      display: block;
    }
    @media screen and (min-width: $sm) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 3rem 1fr;
    }
    @media screen and (min-width: $md) {
      padding: 0 4rem 0 0;
    }

    @media screen and (min-width: $lg) {
      padding: 0 0.5rem 0 0;
    }

    &__title {
      grid-row: 1;
      display: flex;
      align-items: flex-start;
      overflow: hidden;
      h4 {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: $bold;
        margin-bottom: 0.5rem;
      }
    }
    &__image,
    &__text {
      grid-row: 2;
    }
  }
  &__main-item--noTitle {
    display: block;
  }

  &__additional {
    margin-top: 1rem;

    &--stacked {
      @media screen and (min-width: $sm) {
        grid-column: 7 / span 6;
      }

      @media screen and (min-width: $md) {
        grid-column: 5 / span 4;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
      }

      &.sponsors__additional--alone {
        @media screen and (min-width: $sm) {
          grid-column: 1 / span 6;
        }

        @media screen and (min-width: $md) {
          grid-column: 1 / span 4;
        }
      }
    }
  }

  &__additional-list {
    gap: 1rem;

    @media screen and (min-width: $sm) {
      gap: 2rem;
      align-items: start;
    }

    @media screen and (min-width: $md) {
      display: flex;
      gap: 1rem;
    }

    @media screen and (min-width: $lg) {
      display: grid;
      gap: 1.5rem;
    }
  }

  &__additional-item {
    @include body;
    & {
      font-size: 1.125rem;
    }
    @media screen and (min-width: $sm) {
      font-size: 1rem;
    }
  }

  &__sponsor-text {
    margin-top: 1rem;
  }
}
