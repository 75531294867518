@import '../../lib.scss';

.onward-links {
  margin-top: 5rem;
  padding: 5rem 0;
  &__container {
    @include container;
  }
  &__grid {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $md) {
      @include grid(3);
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1rem;
    }
  }
  &__title {
    @include h2;
  }
  &__card {
    position: relative;
    display: grid;
    grid-template-columns: 8rem 1fr 1fr;
    grid-template-areas: 'icon content content';
    margin: 2rem 0 0;
    &__icon {
      grid-area: icon;
      svg {
        min-width: 6rem;
      }
    }
    &__content {
      grid-area: content;
      align-self: center;
    }
    &__title {
      @include h4;
      & {
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 1rem;
        cursor: pointer;
      }
      @media screen and (min-width: $md) {
        justify-content: initial;
      }
      svg {
        min-width: 22px;
        height: 22px;
        top: 0.5rem;
        position: relative;
        transition: transform $transition-time;
      }
      &:hover {
        .onward-links__card__link {
          path {
            transform: translateX(8px);
          }
        }
        svg {
          transform: translateX(8px);
        }
      }
    }
    &__link {
      flex: 0;
      text-decoration: none;
      margin-right: 1rem;
      color: $ra-black;
      &:focus-visible {
        @include linkFocus;
      }
      &:hover {
        text-decoration: underline;
      }
      &:focus-visible {
        text-decoration: none;
      }
      span {
        margin-left: 0.5rem;
      }
    }
  }

  &--theme--ra-blue,
  &--theme--ra-green,
  &--theme--ra-pink,
  &--theme--ra-purple {
    .onward-links__card__link {
      color: $ra-white;
    }
  }

  &--theme--ra-blue {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-light-blue;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-light-blue;
      }
    }
  }
  &--theme--ra-pink {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-light-pink;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-light-pink;
      }
    }
  }
  &--theme--ra-purple {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-light-purple;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-light-purple;
      }
    }
  }
  &--theme--ra-green {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-light-green;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-light-green;
      }
    }
  }

  &--theme--ra-light-blue {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-blue;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-blue;
      }
    }
  }

  &--theme--ra-light-pink {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-pink;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-pink;
      }
    }
  }

  &--theme--ra-light-purple {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-purple;
        &:focus-visible {
          color: $ra-black;
        }
      }

      path {
        fill: $ra-purple;
      }
    }
  }

  &--theme--ra-light-green {
    .onward-links__card__title:hover {
      .onward-links__card__link {
        color: $ra-green;
        &:focus-visible {
          color: $ra-black;
        }
      }
      path {
        fill: $ra-green;
      }
    }
  }
}

.donate + .onward-links,
.half-half + .onward-links {
  margin-top: 1px;
}
