@import '../../../lib.scss';

.future-start-date {
  padding: 1rem;
  @media screen and (min-width: $md) {
    padding: 0;
  }
  &__title {
    @include h4;
  }
  &__components {
    margin-top: 1rem;
  }
  &__options {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (min-width: $md) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
      align-items: stretch;
    }
  }
  &__notes {
    margin-top: 1rem;
  }
  &__additional-information a {
    @include linkInline;
    & {
      color: $ra-blue;
    }
  }
  .date-picker {
    max-width: 22rem;
    &__container {
      padding-bottom: 0;
    }
    button {
      @include buttonFocus;
    }
  }
}
