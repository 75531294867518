@import '../../lib.scss';

.explorer-selected-works {
  @include container;
  & {
    padding: 2rem;
    margin-top: 2rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__title {
    @include h2;
    & {
      @media (max-width: $sm) {
        @include h2;
      }
    }
  }

  &__desktop-cta {
    display: block;

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__mobile-cta {
    display: none;
    margin-top: 1.5rem;
    text-align: center;

    @media (max-width: $sm) {
      display: block;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @media (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
