@import '../../lib.scss';

.benefits-block {
  margin-top: 5rem;
  background-color: $ra-white;
  &__container {
    @include container;
    & {
      display: flex;
      flex-direction: column;
    }
  }
  &__title {
    @include h2;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-top: 2rem;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__card {
    svg {
      height: 50px;
    }
    &__title {
      @include h5;
      & {
        margin-top: 0.5rem;
      }
    }
    &__description {
      margin-top: 0.5rem;
      a:focus-visible {
        text-decoration: none;
        @include linkFocus;
      }
    }
  }
  &__cta {
    align-self: flex-end;
  }
}
