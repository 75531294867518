@import '../../../lib.scss';

.membership-select {
  position: relative;
  width: 100%;
  margin-top: 2rem;

  &:not(.membership-select--selected) {
    background-color: $ra-white;
    .membership-select__selection {
      color: $ra-black;
    }
  }

  @media screen and (min-width: $md) {
    margin: 0;
  }
  &__selection {
    padding: 1rem;
    @media screen and (min-width: $md) {
      height: 100%;
      padding: 2rem 1rem;
    }
  }
  &__title {
    @include h3;
  }
  &__label {
    background-color: $ra-black;
    position: absolute;
    top: -1.5rem;
    left: 0;
    height: 1.5rem;
    line-height: 1.5rem;
    padding: 0 0.5rem;
    font-weight: $bold;
    color: $ra-white;
  }
  &__price {
    @include h4;
    & {
      font-weight: normal;
    }
    span {
      font-weight: $bold;
    }
  }
  &__benefits {
    margin: 1rem 0;
    &__item {
      display: flex;
      align-items: center;
      line-height: 100%;
    }
    &__item + &__item {
      margin-top: 0.5rem;
    }
    &__icon {
      display: flex;
      align-items: center;
      line-height: 100%;
      margin-right: 0.5rem;
      svg {
        height: auto;
        width: 1.5rem;
        + svg {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .secondary-cta {
    margin-top: 0.5rem;
    width: 100%;
    @media screen and (min-width: $sm) {
      width: auto;
    }
    @media screen and (min-width: $md) {
      width: 100%;
    }
  }
  .secondary-cta__inner {
    width: 100%;
  }

  &--selected {
    .friend-signup__dropdown::after {
      content: '';
      background: $ra-white;
      position: absolute;
      top: -1rem;
      width: 2rem;
      height: 2rem;
      left: 50%;
      margin-left: -1rem;
      transform: rotate(45deg);
    }
  }
}
