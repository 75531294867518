@import '../../../lib.scss';

.explorer-basket-header {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: $ra-black;
  z-index: 2;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;
    height: 100%;
    max-width: 88rem;
    margin: 0 auto;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__logo {
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $ra-white;
      gap: 0.5rem;
    }
  }

  &__exhibition-text {
    color: $ra-white;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.01em;
    white-space: pre-line;
  }

  &__timer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      width: 2rem;
      height: 1rem;
    }
  }

  &__timer-text {
    @include body;
    & {
      font-weight: 700;
      line-height: 1.75;
      letter-spacing: -0.01em;
      color: $ra-white;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: $md) {
  .explorer-basket-header {
    &__container {
      padding: 1rem;
    }
    &__exhibition-text {
      font-size: 1.5rem;
    }

    &__timer {
      gap: 0.25rem;
    }
  }
}
