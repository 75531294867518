@import '../../../../lib.scss';

.filter-dropdown {
  line-height: 1rem;
  border-radius: 1rem;
  position: relative;
  @media screen and (min-width: $md) {
    margin: 0.5rem;
  }
  &--active {
    background-color: $ra-light-blue;
    @media screen and (min-width: $md) {
      background-color: none;
    }
    .filter-dropdown__title {
      background: $ra-light-blue;
      border-radius: 1rem 1rem 0 0;
      @media screen and (min-width: $md) {
        border-radius: 1rem;
      }
    }
    &::before {
      display: none;
      @media screen and (min-width: $md) {
        display: block;
      }
    }
    & + .filter-dropdown {
      &::before {
        display: none;
        @media screen and (min-width: $md) {
          display: block;
        }
      }
    }
  }
  &__selected-count {
    z-index: 3;
    position: absolute;
    right: 3rem;
    top: 0.8rem;
    width: 1.5rem;
    height: 1.5rem;
    background: $ra-blue;
    color: $ra-white;
    text-align: center;
    padding: 0.25rem;
    line-height: 1rem;
    border-radius: 1rem;
  }
  + .filter-dropdown {
    &::before {
      content: '';
      position: absolute;
      background-color: $ra-black;
      height: 1px;
      width: calc(100% - 2rem);
      left: 1rem;
      top: -0.5rem;
      @media screen and (min-width: $md) {
        height: 2.5rem;
        width: 1px;
        left: -0.55rem;
        top: 0.25rem;
      }
    }
  }
  &__title {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: $bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    width: 100%;
    cursor: pointer;
    border-radius: 1rem;
    position: relative;
    z-index: 3;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    @include buttonFocus;
    &:hover {
      background: $ra-light-blue;
    }
  }
  &__content {
    background: $ra-light-blue;
    padding: 0.5rem 1.5rem;
    z-index: 2;
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    display: none;
    border-radius: 0 0 1rem 1rem;
    &--show {
      display: block;
      position: relative;
      left: initial;
      top: initial;
      width: 100%;
      height: initial;
      overflow: initial;
      @media screen and (min-width: $md) {
        margin: 1rem 0;
        position: absolute;
        top: 3.5rem;
        left: -0.5rem;
        width: calc(100% + 1rem);
        margin: 0.5rem 0;
      }
    }
    @media screen and (min-width: $md) {
      padding: 2rem 1.5rem 1rem;
      border-radius: 1rem;
      &--2cols {
        width: calc(200% + 2rem);
      }
    }
  }
  &__checkboxes {
    .checkbox:first-child {
      margin-top: 0;
    }
  }
  .checkbox {
    margin: 1rem 0;
  }
}
