@import '../../../lib.scss';

.explorer-listing {
  @include container;

  &__info {
    margin-right: 1rem;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    display: flex;
  }

  &__loading {
    text-align: center;
    padding: 2rem 0;
  }
}
