@import '../../lib.scss';

.listing {
  margin: 5rem 0;
  &__container {
    @include container;
  }
  &__grid {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $sm) {
      align-items: center;
    }
  }
  &__title {
    @include h2;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 2rem 0;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
