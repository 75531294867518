@import '../../../lib.scss';

.payment-method {
  padding: 1rem;
  @media screen and (min-width: $md) {
    padding: 0;
  }
  &__title {
    @include h4;
  }
  &__desc {
    margin-top: 1rem;
  }
  &__options {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (min-width: $md) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
    }
  }
  &__additional-information {
    margin-top: 0.5rem;
    button {
      text-decoration: underline;
      color: $ra-blue;
      text-align: left;
      &:focus-visible {
        @include linkFocus;
      }
    }
    @media screen and (min-width: $md) {
      margin-top: 2rem;
      button {
        color: $ra-white;
        background-color: $ra-blue;
        padding: 1.5rem;
        display: block;
        width: 100%;
        text-align: left;
        font-weight: $bold;
        position: relative;
        text-decoration: none;

        &::after {
          content: '';
          background-image: url('./secondary-arrow.svg');
          width: 1rem;
          height: 1rem;
          position: absolute;
          right: 1.5rem;
          top: 2rem;
          transition: right $transition-time;
        }

        &:hover::after {
          right: 1rem;
        }
      }
    }
  }
  .radio__label {
    @media screen and (min-width: $md) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      br {
        display: none;
      }
    }
  }

  &__more-info {
    @include linkInline;
    & {
      color: $ra-blue;
    }
  }
}
