@import '../../lib.scss';

.image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.placeholder-image {
  width: 100%;
  aspect-ratio: 4/3;
  background: $ra-light-grey;
  &--16x9 {
    aspect-ratio: 16/9;
  }
  &--1x1 {
    aspect-ratio: 1/1;
  }
}
