@import '../../../lib.scss';

.user-menu {
  &__basket-handle {
    &--active button.user-menu__link {
      position: relative;
      @media screen and (min-width: $md) {
        padding-right: 1rem;
      }
      &::after {
        content: '';
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 1rem;
        background-color: $ra-light-blue;
        position: absolute;
        right: 1rem;
        top: 1.3rem;
        @media screen and (min-width: $md) {
          top: 0.3rem;
          right: 0rem;
        }
      }
    }
    &--open {
      button {
        @media screen and (min-width: $md) {
          border-bottom: 8px solid $ra-white;
        }
      }
    }
  }
  .basket {
    position: fixed;
    top: $mobile-nav-height;
    height: calc(100% - $mobile-nav-height);
    transition: left 0.2s ease-in-out;
    @media screen and (min-width: $md) {
      position: absolute;
      top: initial;
      height: inherit;
      transition: none;
    }
  }
  &__link {
    background-color: transparent;
    color: $ra-white;
    cursor: pointer;
    @include h6;
    & {
      padding: 1rem 2rem;
      text-decoration: none;
      display: block;
      font-size: 1.1rem;
      font-weight: 500;
    }
    &:hover {
      text-decoration: underline;
    }
    svg {
      display: none;
    }
    @media screen and (min-width: $md) {
      display: inline-block;
      @include underline-link;
      & {
        padding: 0;
        font-size: 1rem;
      }
      &:focus-visible {
        @include linkFocus;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &[disabled] {
      color: $ra-light-grey;
      opacity: 0.7;
      &:hover {
        svg {
          display: none;
        }
      }
    }
  }
  &__item {
    @media screen and (min-width: $md) {
      display: inline-block;
      padding: 0rem 1.5rem;
    }
  }
  &__friend {
    display: block;
    margin: 1.5rem 0 0 2rem;
    @media screen and (min-width: $md) {
      display: none;
    }
  }
}
