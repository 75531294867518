@import '../../../lib.scss';

.membership-summary {
  padding: 0.5rem;
  background-color: $ra-white;
  @media screen and (min-width: $md) {
    margin: -1rem -2rem 0 0.5rem;
    padding: 0;
  }
  &__container {
    background-color: $ra-light-blue;
    color: $ra-black;
    padding: 0.5rem;
    @media screen and (min-width: $md) {
      padding: 2rem 1rem;
    }
    &.theme--ra-light-green,
    &.theme--ra-green {
      background-color: $ra-light-green;
      .membership-summary {
        &__switch button,
        &__price__amount {
          color: $ra-green;
        }
      }
    }
    &.theme--ra-light-pink,
    &.theme--ra-pink {
      background-color: $ra-light-pink;
      .membership-summary {
        &__switch button,
        &__price__amount {
          color: $ra-pink;
        }
      }
    }
    &.theme--ra-light-purple,
    &.theme--ra-purple {
      background-color: $ra-light-purple;
      .membership-summary {
        &__switch button,
        &__price__amount {
          color: $ra-purple;
        }
      }
    }
  }
  &__title {
    @include h4;
  }
  &__message {
    margin-top: 1rem;
  }
  &__switch {
    button {
      @include linkInline;
      & {
        background-color: transparent;
        color: $ra-blue;
        display: inline;
        margin: 0;
        box-sizing: border-box;
      }
      &:focus-visible {
        @include linkFocus;
      }
    }
  }
  &__price {
    margin-top: 1rem;
    &__amount {
      color: $ra-blue;
      @include h4;
      & {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
  }
  &__button {
    margin-top: 0.5rem;
    @media screen and (min-width: $md) {
      .secondary-cta {
        &,
        &__inner {
          width: 100%;
        }
      }
    }
  }
}
