@import '../../lib.scss';

$mlg: 1350px;

.carousel {
  margin-top: 5rem;
  position: relative;

  &--explorer {
    // card sizes for explorer
    .carousel__card {
      flex: 0 0 18rem;

      @media screen and (min-width: $sm) {
        flex: 0 0 18rem;
      }
    }

    // Adjust list spacing for explorer cards
    .carousel__list {
      > li {
        margin-right: 1.5rem;
      }

      @media screen and (min-width: $sm) {
        justify-content: flex-start;
        > li {
          margin-right: 1.5rem;
        }
      }
    }

    //  embla container for explorer
    .carousel__embla {
      @media screen and (min-width: $sm) {
        padding: 0.5rem 2rem;
        max-width: calc(18rem * 4 + 1.5rem * 3); // 4 cards + 3 gaps
        margin: 1.5rem auto 2rem;
      }
    }

    // Explorer specific nav positioning
    .carousel__nav {
      @media screen and (min-width: $sm) {
        padding: 1rem 0;
      }
    }

    //  stacked behavior for explorer
    &.carousel--stacked {
      .carousel__list {
        flex-direction: row;
        padding: 0;

        > li {
          margin-right: 1.5rem;
          margin-top: 0;
        }

        > li + li {
          margin-top: 0;
        }
      }

      .carousel__card {
        flex: 0 0 18rem;
      }
    }
  }

  &__top {
    @include container;
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    @include h2;
  }

  &__nav {
    display: flex;
    padding: 0.75rem 0 0 1rem;
    @media screen and (min-width: $sm) {
      padding: 1rem 0 1rem 2rem;
    }
  }

  &__button:first-of-type {
    margin-right: 1rem;
  }

  &--stacked &__nav {
    display: none;
    @media screen and (min-width: $sm) {
      display: flex;
    }
  }

  &__button {
    background: none;
    display: block;
    font-size: 0;
    line-height: 0;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    cursor: pointer;
    &:not([disabled]) {
      background-color: $ra-black;
      &:hover {
        background-color: $ra-blue;
      }
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: $ra-white;
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
    @include buttonFocus;
  }

  &__embla {
    width: 100vw;
    overflow: hidden;
    padding: 0.5rem 1rem 0 1rem;
    margin: 1.5rem 0 2rem;
    @media screen and (min-width: $sm) {
      padding: 0.5rem 2rem 0;
    }
  }

  &--stacked &__embla {
    padding: 0;
    margin: 1rem 0 2rem;
    @media screen and (min-width: $sm) {
      padding: 0.5rem 2rem 0;
      margin: 1.5rem 0 2rem;
    }
  }

  &__list {
    display: flex;
    > li {
      margin-right: 2rem;
    }
    @media screen and (min-width: $sm) {
      max-width: 1272px;
      margin: 0 auto;
      flex-wrap: nowrap;
      flex-direction: row;
      > li {
        margin-top: 0;
      }
    }
  }

  &--stacked &__list {
    flex-direction: column;
    padding: 0 1rem;
    > li {
      margin-right: 0;
    }
    > li + li {
      margin-top: 2rem;
    }
    @media screen and (min-width: $sm) {
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 0;
      > li {
        margin-right: 2rem;
      }
      > li + li {
        margin-top: 0;
      }
    }
  }

  &--category &__list {
    @media screen and (min-width: $sm) {
      padding: 0;
      flex-direction: column;
    }
    @media screen and (min-width: $md) {
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }

  &__card {
    flex: 0 0 77%;
    @media screen and (min-width: $sm) {
      flex: 0 0 33%;
    }
  }

  &--stacked &__card {
    flex: 0 0 100%;
    @media screen and (min-width: $sm) {
      flex: 0 0 33%;
    }
  }

  &--category &__card {
    @media screen and (min-width: $sm) {
      flex: 0 0 100%;
    }
    @media screen and (min-width: $md) {
      flex: 0 0 33%;
    }
  }

  &__cta {
    @include container;
    @media screen and (min-width: $sm) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &--category &__cta {
    @media screen and (min-width: $sm) {
      display: block;
    }
    @media screen and (min-width: $md) {
      display: flex;
      justify-content: flex-end;
    }
  }
}
