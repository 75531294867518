@import '../../lib.scss';

.two-three-column {
  margin-top: 5rem;
  &__container {
    @media screen and (min-width: $sm) {
      @include container;
    }
  }
  &__title {
    @include h2;
    & {
      padding-left: 1rem;
    }
    @media screen and (min-width: $sm) {
      padding-left: 0;
    }
  }
  &__columns {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $sm) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
    }
    @media screen and (min-width: $md) {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: left;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 1rem 0 0 1rem;
  margin-top: 2rem;
  @media screen and (min-width: $sm) {
    flex: 0 1 47%;
  }
  @media screen and (min-width: $md) {
    flex: 1 1 100%;
  }
  &--no-image {
    margin: 2rem 1rem 0 1rem;
    padding: 0;
    border-top: 2px solid $ra-light-grey;
    @media screen and (min-width: $sm) {
      margin: 2rem 0 0 0;
    }
  }
  &__image-wrapper {
    position: relative;
    &::before {
      position: absolute;
      width: calc(100% - 1rem);
      height: 100%;
      top: -1rem;
      left: -1rem;
      content: '';
      z-index: -1;
      background-color: $ra-light-grey;
      @media screen and (min-width: $sm) {
        width: 100%;
      }
    }
  }
  &__image {
    padding: 0;
    margin-right: 1rem;
    aspect-ratio: 6/4;
    img {
      object-fit: cover;
      aspect-ratio: 6/4;
    }
    @media screen and (min-width: $sm) {
      margin-right: 0;
    }
  }
  &__title {
    @include h4;
    & {
      margin-top: 1rem;
      margin-right: 1rem;
    }
    @media screen and (min-width: $sm) {
      margin-right: 0;
    }
  }
  &__text {
    margin-top: 0.5rem;
    margin-right: 1rem;
    @media screen and (min-width: $sm) {
      margin-right: 0;
    }
  }
  &__cta {
    margin-top: 1rem;
    margin-right: 1rem;
    @media screen and (min-width: $sm) {
      margin-right: 0;
    }
  }
  &--theme--ra-light-blue {
    .column__image-wrapper:before {
      background-color: $ra-light-blue;
    }
  }

  &--theme--ra-light-pink {
    .column__image-wrapper:before {
      background-color: $ra-light-pink;
    }
  }

  &--theme--ra-light-purple {
    .column__image-wrapper:before {
      background-color: $ra-light-purple;
    }
  }

  &--theme--ra-light-green {
    .column__image-wrapper:before {
      background-color: $ra-light-green;
    }
  }
}

.column + .column {
  @media screen and (min-width: $sm) {
    margin-left: 2rem;
  }
  @media screen and (max-width: ($md - 1px)) {
    &:nth-child(3) {
      margin-left: 0;
    }
  }
}
