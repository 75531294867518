@import '../../lib.scss';

.pagination {
  display: flex;
  margin: 3rem 0;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  @media screen and (min-width: $sm) {
    gap: 1rem;
  }
  &__item {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-right: auto;
    }
    &:last-of-type {
      margin-left: auto;
    }
  }
  &__dots {
    margin-right: 0.5rem;
    @media screen and (min-width: $sm) {
      margin-right: 1rem;
    }
  }
  &__button {
    background-color: transparent;
    min-height: 38px;
    min-width: 38px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    transition: background-color $transition-time;
    @media screen and (min-width: $sm) {
      min-height: 48px;
      min-width: 48px;
    }
    &:hover {
      background-color: $ra-light-grey;
    }
    &:focus-visible {
      @include buttonFocus;
    }
    &--selected {
      background-color: $ra-light-grey;
    }
    &__left-arrow,
    &__right-arrow {
      position: relative;
      background-color: $ra-black;
      color: $ra-white;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 22px;
        height: 22px;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background-color: $ra-blue;
      }
      &--disabled {
        background-color: $ra-light-grey;
        cursor: default;
        &:hover {
          background-color: $ra-light-grey;
        }
      }
    }
    &__left-arrow {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.032 9.75c.072-.328.234-.629.467-.865.27-.262.571-.489.895-.676l.527-.337c.658-.413 1.237-.845 1.823-1.277a39.006 39.006 0 0 0 3.12-2.54A38.194 38.194 0 0 0 9.923.979a3.182 3.182 0 0 1 1.25-.757c.25-.093.508-.168.77-.223a2.113 2.113 0 0 1-.112 2.176c-.218.396-.49.758-.81 1.074 0 0-.25.277-.704.73-.454.452-1.105 1.108-1.935 1.851A42.146 42.146 0 0 1 5.435 8.25c-.439.329-.9.66-1.382.993l1.691.048c1.586.04 3.692.1 5.811.074 4.225-.061 8.555-.453 8.93-.46.698-.06 1.514.804 1.514.804-.076.461-.313.878-.667 1.173-.353.294-.8.447-1.254.429l-2.442.175c-1.474.088-3.468.19-5.515.217-3.389 0-6.844-.135-8.338-.223l.85.865 2.803 2.871c1.684 1.743 2.941 3.04 3.29 3.426 1.376 1.534.98 2.27.98 2.27-1.02.44-2.421-.675-2.421-.675L6.554 17.48l-2.81-2.845-1.593-1.608-.822-.831-.586-.588a2.586 2.586 0 0 1-.612-.878 1.69 1.69 0 0 1-.099-.98Z' fill='%23fff'/%3E%3C/svg%3E");
      }
    }
    &__right-arrow {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.966 9.75a1.763 1.763 0 0 0-.467-.865 4.566 4.566 0 0 0-.895-.676l-.527-.337c-.658-.413-1.237-.845-1.823-1.277a39.014 39.014 0 0 1-3.119-2.54 38.197 38.197 0 0 1-3.06-3.075 3.181 3.181 0 0 0-1.25-.757 5.503 5.503 0 0 0-.77-.223 2.112 2.112 0 0 0 .111 2.176c.219.396.491.758.81 1.074 0 0 .25.277.704.73.454.452 1.106 1.108 1.935 1.851a42.16 42.16 0 0 0 2.948 2.419c.439.329.9.66 1.382.993l-1.691.048c-1.586.04-3.692.1-5.811.074-4.225-.061-8.555-.453-8.93-.46-.698-.06-1.514.804-1.514.804.076.461.313.878.667 1.173.354.294.8.447 1.255.429l2.441.175c1.474.088 3.468.19 5.515.217 3.39 0 6.844-.135 8.338-.223l-.849.865-2.803 2.871c-1.685 1.743-2.942 3.04-3.29 3.426-1.376 1.534-.981 2.27-.981 2.27 1.02.44 2.421-.675 2.421-.675l2.731-2.757 2.81-2.845 1.593-1.608.823-.831.585-.588c.262-.245.47-.545.612-.878.13-.309.164-.65.1-.98Z' fill='%23fff'/%3E%3C/svg%3E");
      }
    }
  }
}
