@import '../../lib.scss';

.alert-banner {
  width: 100%;
  padding: 0 1rem;
  position: fixed;
  top: $desktop-nav-height;
  z-index: 6;
  transition: top 0.2s;
  &--high {
    background-color: $ra-red;
    color: $ra-white;
  }
  &--low {
    .alert-banner__close {
      background-color: rgba($ra-white, 0);
      &:hover {
        background-color: rgba($ra-white, 0.7);
      }
    }
  }
  &--with-button {
    padding-right: 3rem;
  }
  &__inner {
    padding: 0.5rem 0;
  }
  &__text {
    font-weight: bold;
    margin: 0;
  }
  a {
    @include linkInline;
    & {
      color: inherit;
    }

    &:focus-visible {
      @include linkFocus;
      border-bottom: 0;
      color: $ra-black;
    }
  }
  &__close {
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    border-radius: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    line-height: 1rem;
    color: inherit;
    background-color: rgba($ra-black, 0);
    cursor: pointer;
    svg {
      height: 1rem;
      width: auto;
    }
    &:hover {
      transition: all $transition-time;
      background-color: rgba($ra-black, 0.7);
    }
    @include buttonFocus;
  }
}

.header--scrolling ~ .alert-banner {
  top: $scrolling-nav-height;
}

@media screen and (max-width: ($md - 1px)) {
  .alert-banner {
    top: $mobile-nav-height;
  }
  .header--scrolling ~ .alert-banner {
    top: $mobile-nav-height;
  }
}
