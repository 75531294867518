@import '../../lib.scss';

.article-half-half {
  margin: 5rem 0;
  &__container {
    @include container;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'text';
    gap: 1rem;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'image text';
      gap: 2rem;
    }
  }
  &--right &__grid {
    @media screen and (min-width: $sm) {
      grid-template-areas: 'text image';
    }
  }
  &__image {
    grid-area: image;
  }
  &__text {
    grid-area: text;
  }
}
