@import '../../lib.scss';

.external-embed {
  margin-top: 5rem;
  &__container {
    @include container;
  }
  &__title {
    @include h2;
    & {
      margin-bottom: 1rem;
    }
  }
  &__description {
    @include body;
  }
  &__grid {
    &--2col {
      @media screen and (min-width: $md) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 2rem;
        .external-embed__text {
          grid-column: 1 / span 6;
        }
        .external-embed__content {
          grid-column: 7 / span 6;
        }
      }
    }
  }
}

.ra__cookieOptOut {
  padding-top: 56.7%;
  width: 100%;
  height: 0;
  position: relative;
  background: #efefef;
  .ra__cookieOptOut__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    p,
    h4,
    a {
      color: black;
    }
    a {
      text-decoration: underline;
    }
  }
}
