@import '../../../lib';

.basket {
  position: relative;
  z-index: 9;
  &__inner {
    padding: 1rem;
    overflow: hidden;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(42, 42, 42, 0.4);
    position: fixed;
    top: $desktop-nav-height;
    left: 0;
  }
  h2 {
    @include h4;
    & {
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
  &__section + &__section {
    border-top: 2px solid $ra-light-grey;
    margin-top: 2rem;
    padding-top: 2rem;
  }
  &__item {
    display: flex;
    align-items: center;
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  }
  &__item + &__item {
    margin-top: 1rem;
  }
  &__list {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: $md) {
    position: fixed;
    width: 100%;
    &__close {
      color: $ra-white;
      background-color: $ra-black;
      padding: 2rem 3rem;
      @include h5;
      text-decoration: none;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      svg {
        margin-right: 1rem;
      }
    }
  }
  @media screen and (min-width: $md) {
    width: 39rem;
    right: 0;
    &__inner {
      padding: 2rem;
    }
    &__close {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 2rem;
      top: 2rem;
      z-index: 1;
      svg {
        width: 1rem;
        height: 1rem;
      }
      @include buttonFocus;
    }

    &__section--se {
      .secondary-cta {
        float: right;
      }
    }
    &__section--tnew {
      position: relative;
      .secondary-cta {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
