@import '../../../lib.scss';

.explorer-filters {
  padding: 2rem 0;

  @media screen and (min-width: $md) {
    padding: 2rem 0 1rem 0;
  }

  &__container {
    @include container();
  }

  &__grid {
    display: grid;
    gap: 1rem;

    @media screen and (min-width: $md) {
      @include grid(12, 1fr);
      gap: 1rem 2rem;
      align-items: center;
    }
  }

  &__title {
    @include h1;
    & {
      color: $ra-white;
      margin-bottom: 1rem;

      @media screen and (min-width: $md) {
        grid-column: 1 / span 12;
        grid-row: 1;
        margin-bottom: -3rem;
      }
    }
  }

  &__mobile-button {
    display: flex;
    align-items: center;
    width: 7.9375rem;
    height: 2.875rem;
    padding: 0.25rem 1rem 0.25rem 1.25rem;
    margin-bottom: 1rem;
    border: 0.125rem solid $ra-white;
    border-radius: 2.5rem;
    background-color: transparent;
    color: $ra-white;
    cursor: pointer;

    @media screen and (min-width: $md) {
      display: none;
    }
  }

  &__mobile-button-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__mobile-button-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2.375rem;
  }

  &__content {
    @media screen and (max-width: $md) {
      &--hidden {
        display: none;
      }
    }

    @media screen and (min-width: $md) {
      grid-column: 1 / span 12;
      grid-row: 2;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1rem;
    }
  }

  &__checkboxes {
    margin-bottom: 1rem;

    @media screen and (min-width: $md) {
      grid-column: 7 / span 6;
      grid-row: 1;
      justify-self: end;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .checkbox {
      margin: 0 2rem 0 0;

      &__input {
        border-color: $ra-white;
      }
    }
  }

  &__filters {
    margin: 1rem 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.25rem;

    @media screen and (min-width: $md) {
      grid-column: 1 / span 12;
      margin: 0;
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
    }

    .filter-dropdown {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: $md) {
        width: 32%;
      }

      & + .filter-dropdown {
        margin-top: 1rem;

        @media screen and (min-width: $md) {
          margin-top: 0;
        }
      }
    }
  }

  &__selected-filters {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;

    @media screen and (min-width: $md) {
      grid-row: 3;
      grid-column: 1 / span 9;
      min-height: 3rem;
    }

    button {
      background-color: $ra-light-grey;
    }
  }

  &__clear-filters {
    margin-top: 1rem;
    margin-left: auto;

    @media screen and (min-width: $md) {
      grid-column: 10 / span 3;
      text-align: right;
      margin-right: 1rem;
    }
  }

  h1 {
    color: $ra-white;
  }

  &__apply-button {
    margin-top: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem 0.25rem 1.25rem;
    gap: 0.75rem;
    width: 100%;
    height: 2.875rem;
    left: 1rem;
    top: 46.625rem;
    border: 0.125rem solid $ra-white;
    border-radius: 2.5rem;
    background-color: $ra-black;
    color: $ra-white;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &__applied-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__clear-all {
    background: none;
    border: none;
    color: $ra-white;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
  }

  .tag {
    background-color: $ra-light-grey;
    color: $ra-black;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
