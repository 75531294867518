@import '../../lib.scss';

.half-half {
  margin-top: 5rem;
  &__wrapper {
    overflow-x: hidden; // fixes a bug in Safari and chrome on mobile
  }
  &__container {
    @media screen and (min-width: $md) {
      @include container;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 3rem 1fr;
    @media screen and (min-width: $md) {
      @include grid(12, 1fr);
      grid-template-rows: 9rem 1fr;
    }
  }
  &__image {
    grid-column: 1 / span 4;
    grid-row: 1 / 3;
    z-index: 1;
    margin-right: 1rem;
    margin-left: 0;
    aspect-ratio: 16/9;
    @media screen and (min-width: $sm) {
      grid-column: 1 / -1;
    }
    @media screen and (min-width: $md) {
      grid-column: 1 / span 6;
      grid-row: 2 / 3;
      z-index: 1;
      margin: auto 0 0 0;
    }
    @media screen and (min-width: $md) {
      margin-left: -2rem;
    }
    @media screen and (min-width: $lg) {
      margin-left: -5rem;
    }
  }

  &--right &__image {
    margin-left: 0;
    margin-right: 1rem;
    @media screen and (min-width: $sm) {
      margin-right: 4rem;
    }
    @media screen and (min-width: $md) {
      margin-right: -2rem;
      grid-column: 7 / span 6;
    }
    @media screen and (min-width: $lg) {
      margin-right: -5rem;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 8;
    grid-row: 2 / 3;
    margin-left: 0;
    margin-right: 0;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 100%;
      width: 50%;
      transform: translateX(50%);
      z-index: -1;
      background-color: inherit;
      @media screen and (min-width: $sm) {
        display: none;
      }
    }
    @media screen and (min-width: $sm) {
      margin-left: 2rem;
    }
    @media screen and (min-width: $md) {
      @include grid(8);
      grid-column: 5 / span 8;
      grid-row: 1 / 3;
      grid-template-areas: '. . content content content content content content';
      margin: 0;
    }
    @media screen and (min-width: $md) {
      padding: 5rem 2rem 3rem 5rem;
      margin-right: -2rem;
      &::after {
        content: none;
      }
    }
    @media screen and (min-width: $lg) {
      grid-template-areas: '. content content content content content content content';
      padding: 5rem 5rem 3rem 5rem;
      margin-right: -5rem;
    }
  }

  &--right &__content {
    margin-right: 0;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: initial;
      height: 100%;
      width: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background-color: inherit;
    }
    @media screen and (min-width: $sm) {
      margin-left: 2rem;
    }
    @media screen and (min-width: $md) {
      grid-template-areas: 'content content content content content content . . ';
      grid-column: 1 / span 8;
      padding: 5rem 2rem 3rem 2rem;
      margin-left: -2rem;
      &::after {
        content: none;
      }
    }
    @media screen and (min-width: $lg) {
      grid-template-areas: 'content content content content content content content . ';
      padding: 5rem 5rem 3rem 5rem;
      margin-left: -5rem;
    }
  }

  &__inner {
    padding: 1rem;
    padding-top: calc(56.25% - 1rem);
    padding-bottom: 3rem;
    @media screen and (min-width: $md) {
      margin-top: initial;
      grid-area: content;
      padding: 0;
    }
    @media screen and (min-width: $lg) {
      padding-left: 2rem;
    }
  }

  &--right &__inner {
    @media screen and (min-width: $md) {
      padding: 0;
    }
  }
  &__title {
    @include h2;
  }
  &__description {
    margin-top: 1rem;
    @include intro-text;
    p + p {
      margin-top: 1rem;
    }
    ul {
      position: relative;
      padding-left: 2rem;
      li {
        position: relative;
        &::before {
          background: url('./tick-black.svg') no-repeat center;
          height: 1rem;
          width: 1rem;
          top: 0.4rem;
          background-size: contain;
          position: absolute;
          left: -2rem;
          content: '';
        }
      }
    }
  }
  &__cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    a {
      margin-top: 1rem;
      margin-right: 2rem;
    }
  }
  &--light {
    .half-half__description ul li::before {
      background: url('./tick-white.svg') no-repeat center;
    }
  }
}
