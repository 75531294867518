@import '../../../lib.scss';

.whats-on-categories {
  &__category + &__category {
    margin: 5rem 0;
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  &__title {
    @include h2;
    @media screen and (min-width: $sm) {
      @include h3;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
}
