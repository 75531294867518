@import '../../lib.scss';

.accordion {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $ra-light-grey;
  &__button {
    display: flex;
    justify-content: space-between;
    background: none;
    border: 0;
    cursor: pointer;
    @include small;
    & {
      font-weight: $bold;
    }
    &:focus-visible {
      .accordion__caret {
        @include linkFocus;
        background: #f0db3d;
        box-shadow: 0px 6px $ra-black;
      }
    }
  }

  &--wrapper &__button {
    @include body;
    & {
      font-weight: $bold;
      padding: 1rem 0 0.5rem 0;
    }
  }
  &__label {
    text-align: left;
    margin-right: 1rem;
  }
  &__caret {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
    margin: 0 0 0 auto;
    justify-self: flex-end;
    &::after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 10px;
      margin-left: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.185 3.701.623.636c.734.748 1.433 1.462 2.051 2.098 1.24 1.265 2.172 2.2 2.447 2.457 1.096 1.03 1.622.734 1.622.734.308-.764-.464-1.813-.464-1.813L12.495 5.77c-.593-.616-1.284-1.335-2.032-2.103-.371-.384-.757-.788-1.148-1.192-.198-.202-.396-.409-.594-.616l-.42-.438a1.888 1.888 0 0 0-.627-.463 1.235 1.235 0 0 0-.71-.069c-.241.054-.439.173-.617.35-.184.172-.324.438-.469.67l-.24.394c-.295.472-.604.93-.913 1.364a30.101 30.101 0 0 1-1.82 2.324A27.948 27.948 0 0 1 .71 8.281c-.265.251-.439.626-.55.946-.11.315-.159.576-.159.576.507.335 1.1.177 1.554-.084.454-.26.767-.605.767-.605s.194-.183.522-.522a31.78 31.78 0 0 0 3.05-3.655c.236-.33.473-.68.71-1.034.231-.335.728-1.088 1.08-.729l.502.527Z' fill='%231D1D1B'/%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      transform: rotate(-180deg);
      transition: transform $transition-time;
    }
    &--open {
      &::after {
        transform: rotate(0deg);
      }
    }
  }

  &--wrapper &__caret {
    top: 2px;
    position: relative;
    &::after {
      width: 20px;
      height: 12px;
      transform: rotate(0deg);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='13' fill='none'%3E%3Cpath fill='%231D1D1B' d='m9.086 8.398-.83-.847c-.978-.998-1.911-1.95-2.735-2.797C3.867 3.066 2.625 1.819 2.26 1.477.798.105.097.5.097.5c-.412 1.018.617 2.416.617 2.416L3.34 5.64c.791.821 1.711 1.78 2.709 2.804.495.512 1.01 1.05 1.531 1.59.264.268.528.544.792.82l.56.585c.257.275.54.492.836.617.29.118.618.157.946.092.322-.073.586-.23.824-.467.245-.23.431-.584.624-.893l.322-.525c.392-.63.804-1.24 1.216-1.819a40.13 40.13 0 0 1 2.426-3.099c1.532-1.773 2.748-2.89 2.928-3.053.354-.335.586-.834.734-1.261.148-.42.212-.768.212-.768-.676-.447-1.467-.237-2.072.111s-1.023.808-1.023.808-.258.243-.695.696a41.714 41.714 0 0 0-4.067 4.872c-.315.44-.63.906-.946 1.38-.309.446-.972 1.45-1.442.97l-.669-.702Z'/%3E%3C/svg%3E");
    }
    &--open {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__panel {
    position: relative;
    max-height: 0;
    transition: max-height $transition-time;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &--open {
      -webkit-line-clamp: unset;
      max-height: 100vh;
      &::after {
        content: none;
      }
      .accordion__body p {
        display: block;
      }
    }
  }
  // This line is required for the ellipses to work in Safari
  &__body {
    @include small;
    p {
      display: inline;
    }
  }
  &--wrapper &__body {
    @include body;
    & {
      max-width: 720px;
      padding-bottom: 2rem;
    }
  }
  &__copyright {
    margin-top: 1rem;
    @include small;
  }
}
