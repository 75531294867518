@import '../../lib';

.basic-video {
  @include container;
  & {
    margin-top: 3rem;
  }
  @media screen and (min-width: $md) {
    margin-top: 5rem;
  }

  &__grid {
    @media screen and (min-width: $md) {
      @include grid;
    }
  }

  &__info {
    order: 2;
    grid-column: 9 / span 4;
  }

  &__content {
    @media screen and (min-width: $md) {
      order: 1;
      grid-column: 1 / span 8;
    }
  }

  &__title {
    @include h2;
  }

  &__description {
    margin-top: 1rem;
    a {
      @include linkInline;
    }
  }

  &__video,
  &__cookie {
    position: relative;
    margin-top: 1rem;
    // Make the embedded video responsive at 16:9 aspect ratio
    padding-bottom: 56.25%;
    overflow: hidden;

    &--no-desc {
      margin-top: 1rem;

      @media screen and (min-width: $md) {
        margin-top: 0;
      }
    }

    @media screen and (min-width: $md) {
      margin-top: 0;
    }
  }

  &__cookie {
    background-color: $ra-light-grey;
  }

  &__iframe {
    border: 0;
  }

  &__iframe,
  &__cookie-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__cookie-inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 1rem;
    @media screen and (min-width: $md) {
      padding: 0 3rem;
    }
    > * + * {
      margin-top: 0.5rem;
    }
  }

  &__cookie-title {
    @include h5;
  }

  &__cookie-link {
    box-shadow: 0 1px $ra-black;
    text-decoration: none;
    &:hover,
    &:active {
      box-shadow: 0 4px $ra-black;
    }
    &:visited {
      color: $ra-blue;
    }
    &:focus,
    &:focus-visible {
      text-decoration: none;
      @include linkFocus;
    }
  }

  &__transcription {
    margin-top: 0.5rem;
    p {
      font-size: 1rem;
    }
  }

  .accordion__caret::after {
    margin-left: 0.5rem;
  }

  .accordion__panel {
    margin-top: 0.5rem;
  }
}
