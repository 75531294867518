@import '../../lib.scss';

.explorer-header {
  background-color: $ra-black;
  color: $ra-white;
  padding: 2rem;

  &.explorer-header--scrolling {
    .explorer-header__container {
      height: $scrolling-nav-explorer;
      padding-top: 0;
      padding-bottom: 0;
    }
    .explorer-header__navigation {
      ul {
        padding-top: 2.5rem;
      }
    }

    .header__logo {
      padding: 1rem 0 0.5rem;
      z-index: 10;
      svg {
        height: 49px;
        width: auto;
      }
    }

    .search-dropdown__overlay,
    .basket__overlay,
    .primary-navigation__subnav__overlay {
      top: $scrolling-nav-explorer;
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__left {
    display: flex;
    height: 100%;
    flex-direction: row;
  }

  &__logo {
    font-size: 2rem;
    font-weight: bold;
    margin-right: 1rem;
    svg {
      height: 44px;
      width: auto;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  &__right {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__search {
    position: relative;
    display: flex;
    align-items: center;

    input {
      background-color: $ra-light-grey;
      padding: 0.5rem 0.5rem 0.5rem 2rem;
      border: none;
      border-radius: 1rem;
      width: 300px;
    }

    .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $ra-black;
      pointer-events: none;
      width: 18px;
      height: 18px;
    }
  }

  &__signin,
  &__basket {
    color: $ra-white;
    text-decoration: none;
    margin-left: 1rem;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }

  &__basket {
    position: relative;
  }

  &__basket-icon,
  &__signin-icon {
    margin-left: 0.5rem;
  }

  &__basket-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: $ra-red;
    color: $ra-white;
    font-size: 0.8rem;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__navigation {
    padding-top: 0;

    ul {
      list-style: none;
      display: flex;
      padding-top: 3.5rem;
      margin: 0;
      justify-content: left;
    }

    li {
      margin: 0 15px;
    }

    a {
      color: $ra-white;
      text-decoration: none;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: ($md)) {
    padding-top: $mobile-nav-height;

    &__container {
      position: fixed;
      top: 0;
      left: -100vw;
      height: 100%;
      width: 100%;
      z-index: 7;
      padding: 1rem;
      padding-top: $mobile-nav-height;
      transition: left 0.2s ease-in-out;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__grid {
      flex-direction: column-reverse;
    }

    .basket,
    .search-dropdown,
    .primary-navigation__subnav {
      left: 200vw;
      display: block;
    }

    &--open {
      .header__container,
      .user-menu__basket-handle--open .basket,
      .user-menu__search-handle--open .search-dropdown,
      .primary-navigation__subnav--open {
        left: 0;
      }
    }

    &__mobile {
      padding: 1rem 1rem 0.6rem;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      display: flex;
      align-items: center;
      height: $mobile-nav-height;
      overflow: hidden;
      background-color: $ra-black;
    }
    &__logo {
      margin-right: 0.5rem;
    }
    &__title {
      @include h5;
      margin: 0;
      line-height: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__mobile-actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__mobile-button {
      background: transparent;
      border: none;
      padding: 0.5rem;
      color: $ra-white;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &__navigation {
      display: none;
      background: $ra-black;
      position: fixed;
      left: 0;
      right: 0;
      top: $mobile-nav-height;
      bottom: 0;
      z-index: 10;
      overflow-y: auto;
      transition: opacity 0.3s ease;
      opacity: 0;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--open {
      .explorer-header__navigation {
        display: flex;
        opacity: 1;
        pointer-events: auto;
      }
    }

    &__mobile-nav-footer {
      padding: 1.5rem 2rem;
      margin-top: auto;
    }
  }

  @media screen and (min-width: $md) {
    padding-top: $desktop-nav-height;

    &__container {
      padding: 2rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: $desktop-nav-height;
      z-index: 9;
      transition: all 0.2s;
      background-color: $ra-black;
    }

    &__menu-toggle {
      button {
        color: inherit;
        background: transparent;
        cursor: pointer;
      }
    }

    ul {
      list-style: none;
    }

    &__logo {
      position: relative;
      z-index: 1;
      padding-bottom: 1rem;
      float: left;
      z-index: 10;
      svg {
        height: 84px;
        transition: all 0.2s;
      }
    }

    .user-menu__navigation,
    .user-menu__friend {
      transition: all 0.2s;
    }

    .primary-navigation {
      position: absolute;
      bottom: 0;
      left: 0;
      background: transparent;
    }

    .user-menu {
      position: absolute;
      top: 0.5rem;
      right: 0;
      background: transparent;
    }
  }
}
