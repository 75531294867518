@import '../../../lib.scss';

.confirm-age {
  padding: 1rem;
  @media screen and (min-width: $md) {
    padding: 0;
  }
  &__title {
    @include h4;
  }
  &__desc {
    margin-top: 1rem;
  }
  &__wrapper {
    @media screen and (min-width: $md) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
    }
  }
  .form-field {
    margin-top: 1rem;
  }
}
