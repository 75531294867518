@import '../../lib.scss';

.landing-hero {
  background-color: $ra-white;

  @include container;
  &__grid {
    @include grid;
  }
  &__image {
    order: 1;
    grid-column: 1 / -1;
    margin: 0 -1rem;
    height: 100%;
    max-height: 590px;
    aspect-ratio: 1/1;
    @media screen and (min-width: $sm) {
      order: 2;
      grid-column: 6 / span 7;
      margin: 0 -2rem 0 0;
    }
    @media screen and (min-width: $md) {
      aspect-ratio: 4 / 3;
    }
    @media screen and (min-width: $lg) {
      margin-right: -5rem;
      aspect-ratio: 16 / 9;
    }
    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &__info-wrapper {
    order: 2;
    margin-top: 1rem;
    grid-column: 1 / -1;
    background-color: $ra-white;
    @media screen and (min-width: $sm) {
      order: 1;
    }
    @media screen and (min-width: $md) {
      margin-top: 4rem;
      grid-column: 1 / span 9;
    }
    @media screen and (min-width: $lg) {
      grid-column: 1 / span 8;
    }
  }

  &__title {
    @include h1;
  }

  &__desc {
    @include intro-text;
    & {
      margin-top: 1rem;
    }
    a:focus-visible {
      @include linkFocus;
    }
  }

  &--with-image {
    .landing-hero__info-wrapper {
      margin-right: -1rem;
      margin-top: -1rem;
      padding: 1rem 1rem 0;
      @media screen and (min-width: $sm) {
        grid-column: 1 / span 5;
        padding: 0;
        margin: 0 -2rem 0 0;
        overflow: visible;
        display: flex;
        align-items: flex-start;
      }
    }

    .landing-hero__info {
      background-color: $ra-white;
      overflow: visible;

      @media screen and (min-width: $sm) {
        position: relative;
        margin-right: -2rem;
        padding: 2rem 2rem 2rem 0;
        transform: translateY(5%);
      }
      @media screen and (min-width: $md) {
        transform: translateY(10%);
        min-width: calc(100% + 2rem);
      }
      @media screen and (min-width: $lg) {
        margin-right: -4rem;
        transform: translateY(15%);
      }
    }
  }
}
