@import '../../../lib.scss';

.second-card-holder {
  padding: 1rem;
  @media screen and (min-width: $md) {
    padding: 0;
  }
  &__title {
    @include h4;
  }
  &__desc {
    margin-top: 1rem;
  }
  &__form {
    &__row {
      margin-top: 1rem;
      &--inline {
        display: flex;
        gap: 2rem;
        .dropdown {
          width: 20%;
        }
        .radio {
          width: 50%;
        }
      }
    }
  }
}
