@import '../../../lib.scss';

.artwork-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  transition: transform $transition-time;
  background-color: $ra-white;
  padding-bottom: 0.5rem;

  &:hover {
    transform: translateY(-0.4rem);
  }

  &__link {
    text-decoration: none;
  }

  &__title {
    @include h5;
    & {
      transition: text-decoration 0.3s ease;
    }

    .artwork-card__link:hover & {
      text-decoration: underline;
      color: $ra-blue;
    }
  }

  &__artist-name {
    color: $summer-grey;
    font-size: 1rem;
  }

  &__artwork-title {
    @include h6;
    & {
      display: block;
      font-weight: bold;
    }
  }

  &__image {
    height: 20rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
    }
  }

  &__subtitle {
    @include body;
    & {
      margin-top: 0.5rem;
      text-transform: capitalize;
    }
  }

  &__edition-available {
    display: inline-block;
    padding: 0.3rem 1rem;
    color: $summer-grey;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 9999px;
    border: 1px solid $summer-grey;
    white-space: nowrap;
    margin-top: 1rem;
  }

  &__price {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  &__category {
    font-size: 1rem;
    margin-top: 1rem;
  }
}
