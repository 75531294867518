@import '../../lib.scss';

.tag {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  line-height: 1rem;
  margin: 0 1rem 1rem 0;
  display: inline-flex;
  cursor: pointer;
  @include buttonFocus;
  svg {
    margin-left: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
}
