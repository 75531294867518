@import '../../lib.scss';

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.form-field {
  &__input {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $ra-black;
    background-color: $ra-white;
    background-clip: padding-box;
    border: 1px solid $ra-white;
    border-radius: 1rem;
    &:focus-visible {
      color: $ra-black;
      background-color: $ra-white;
      outline: none;
      border: 3px solid $ra-black;
      box-shadow: 0 0 0 6px $ra-yellow;
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    &--required::after {
      content: '*';
    }
  }

  &__help {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    line-height: 120%;
    p + p {
      margin-top: 0.5rem;
    }
  }

  &--inline-half {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: center;
    label {
      margin-bottom: 0;
      grid-column: 1 / 2;
      @include h5;
    }
    &__field {
      grid-column: 2 / 2;
    }
  }
  &--border {
    input {
      border: 1px solid $ra-black;
    }
  }
  &--inline-button {
    position: relative;
    input {
      height: 3rem;
    }
    button {
      position: absolute;
      right: -1px; // fix bug in Safari
      top: 0;
      height: 3rem;
      background-color: $ra-black;
      color: $ra-white;
      padding: 0.75rem;
      border-radius: 0 0.5rem 0.5rem 0;
      svg {
        height: 1.5rem;
      }
      @include buttonFocus;
    }
  }
  &--error {
    input {
      border: 1px solid $ra-red;
      outline: 2px solid $ra-red;
    }
    label {
      color: $ra-red;
    }
  }
  &__error {
    color: $ra-red;
    margin-top: 1rem;
  }
}

.checkbox {
  display: flex;
  align-items: center;

  &__input {
    -webkit-apperance: none;
    appearance: none;
    background-color: none;
    margin: 0;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 0.25rem;
    border: $ra-black 1px solid;
    margin-right: 0.5rem;

    @media screen and (min-width: $sm) {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:checked {
      background-image: url('tick-black.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75%;
    }

    &--theme--ra-black {
      border-color: $ra-white;
      &:checked {
        background-color: $ra-white;
      }
    }

    &--theme--ra-blue {
      border-color: $ra-light-blue;
      &:checked {
        background-color: $ra-light-blue;
      }
    }

    &--theme--ra-light-blue {
      border-color: $ra-blue;
      &:checked {
        background-color: $ra-blue;
        background-image: url('tick-white.svg');
      }
    }

    &--theme--ra-pink {
      border-color: $ra-light-pink;
      &:checked {
        background-color: $ra-light-pink;
      }
    }

    &--theme--ra-light-pink {
      border-color: $ra-pink;
      &:checked {
        background-color: $ra-pink;
        background-image: url('tick-white.svg');
      }
    }

    &--theme--ra-purple {
      border-color: $ra-light-purple;
      &:checked {
        background-color: $ra-light-purple;
      }
    }

    &--theme--ra-light-purple {
      border-color: $ra-purple;
      &:checked {
        background-color: $ra-purple;
        background-image: url('tick-white.svg');
      }
    }

    &--theme--ra-green {
      border-color: $ra-light-green;
      &:checked {
        background-color: $ra-light-green;
      }
    }

    &--theme--ra-light-green {
      border-color: $ra-green;
      &:checked {
        background-color: $ra-green;
        background-image: url('tick-white.svg');
      }
    }

    &:focus-visible {
      border-color: $ra-black;
      border-width: 0;
      outline: none;
      box-shadow: 0 0 0 3px $ra-black, 0 0 0 6px $ra-yellow;
      transition: box-shadow $transition-time;
    }
  }
  &__input + &__input {
    margin-top: 0.5rem;
  }
}

.radio {
  position: relative;
  &__label {
    padding: 1rem 1rem 1rem 4rem;
    border: $ra-blue 1px solid;
    border-radius: 2rem;
    width: 100%;
    line-height: 1.5rem;
    display: block;
  }
  &__input {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    @media screen and (min-width: $sm) {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:checked ~ label {
      background-color: $ra-light-blue;
      border: 1px solid $ra-light-blue;
    }

    &:focus-visible {
      border-color: $ra-black;
      border-width: 0;
      outline: none;
      box-shadow: 0 0 0 3px $ra-black, 0 0 0 6px $ra-yellow;
      transition: box-shadow $transition-time;
    }
  }

  &--theme--ra-blue {
    border-color: $ra-blue;
    &:checked {
      background-color: $ra-light-blue;
    }
  }

  &--theme--ra-light-blue {
    border-color: $ra-blue;
    &:checked {
      background-color: $ra-blue;
      background-image: url('tick-white.svg');
    }
  }

  &--theme--ra-pink {
    border-color: $ra-light-pink;
    &:checked {
      background-color: $ra-light-pink;
    }
  }

  &--theme--ra-light-pink {
    border-color: $ra-pink;
    &:checked {
      background-color: $ra-pink;
      background-image: url('tick-white.svg');
    }
  }

  &--theme--ra-purple {
    border-color: $ra-light-purple;
    &:checked {
      background-color: $ra-light-purple;
    }
  }

  &--theme--ra-light-purple {
    border-color: $ra-purple;
    &:checked {
      background-color: $ra-purple;
      background-image: url('tick-white.svg');
    }
  }

  &--theme--ra-green {
    border-color: $ra-light-green;
    &:checked {
      background-color: $ra-light-green;
    }
  }

  &--theme--ra-light-green {
    border-color: $ra-green;
    &:checked {
      background-color: $ra-green;
      background-image: url('tick-white.svg');
    }
  }
}

.dropdown {
  &__label {
    font-weight: $bold;

    &--required::after {
      content: '*';
    }
  }

  &__desc {
    margin-top: 0.5rem;
  }

  &__select {
    margin-top: 0.5rem;
    border: 1px solid $ra-black;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    -moz-apperance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    width: 100%;
    background-color: $ra-white;
    background-image: url('arrow-down.svg');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1rem;

    // &:has(.dropdown__option--default:checked) {
    //   font-weight: $bold;
    // }

    &:focus-visible {
      color: $ra-black;
      background-color: $ra-white;
      outline: 3px solid $ra-black;
      box-shadow: 0 0 0 6px $ra-yellow;
      transition: box-shadow $transition-time;
    }
  }
}

.dropdown-checkbox {
  position: relative;
  border-radius: 1.5rem;
  background-color: $ra-white;
  padding: 0.5rem;
  &__title {
    border: 1px solid $ra-black;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    -moz-apperance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    width: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include buttonFocus;
    .icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-top: -6px;
    }
  }
  &__selected-count {
    z-index: 3;
    position: absolute;
    right: 3rem;
    top: 0.8rem;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    padding: 0.25rem;
    line-height: 1rem;
    border-radius: 1rem;
  }
  &__options {
    margin-top: 1rem;
    border: 1px solid $ra-black;
    border-radius: 1rem;
    padding: 1rem;
    position: absolute;
    z-index: 5;
    width: 100%;
    left: 0;
  }
  &__option {
    margin: 1rem 0.5rem;
  }
}
