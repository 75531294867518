@import '../../../lib.scss';

.friend-address {
  padding-bottom: 1rem;
  &__form {
    &__row {
      margin-top: 1rem;
      &--postcode {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        button {
          align-self: flex-start;
        }
      }
    }
  }
}
