@import '../../lib.scss';

.basket-page {
  @include container;
  & {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: $ra-white;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    position: relative;

    @media screen and (min-width: $md) {
      flex-direction: row;
      padding-top: 4rem;
    }
  }

  &__main {
    padding: 2rem 1rem;
    width: 100%;
    position: relative;

    @media screen and (min-width: $sm) {
      padding: 2rem 1.5rem;
    }

    @media screen and (min-width: $md) {
      padding: 0 1rem;
      width: calc(100% - 20rem);
    }
  }

  &__title {
    @include h3;
    & {
      font-weight: 700;
      line-height: 2.5rem;
      color: $ra-black;
      margin-bottom: 1.5rem;

      @media screen and (min-width: $md) {
        line-height: 3.25rem;
        margin-bottom: 1.75rem;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0 0 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $summer-grey;

    @media screen and (min-width: $sm) {
      flex-direction: row;
      gap: 1.5rem;
    }

    @media screen and (min-width: $md) {
      gap: 2rem;
      padding-bottom: 2.25rem;
    }
  }

  &__item-image {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    position: relative;
    overflow: visible;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    @media screen and (min-width: $sm) {
      width: 12.5rem;
      height: auto;
    }
  }

  &__item-details {
    width: 100%;
    padding-bottom: 0;
  }

  &__item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.25rem;
  }

  &__item-catalogue {
    @include small;
    & {
      color: $ra-black;
    }
  }

  &__item-remove {
    padding: 0 0.2rem;
    background: none;
    border: 1px solid $ra-light-grey;
    color: $summer-grey;
    cursor: pointer;
  }

  &__item-title {
    @include body;
    & {
      font-weight: 400;
      color: $summer-grey;
    }
  }

  &__item-artist {
    @include h5;
    & {
      color: $ra-black;
      margin-bottom: 0.5rem;
    }
  }

  &__item-type {
    @include small;
    & {
      font-weight: 400;
      color: $summer-grey;
    }
  }

  &__item-divider {
    border: none;
    height: 1px;
    background: $ra-light-grey;
    margin: 1rem 0;
  }

  &__item-price,
  &__item-deposit {
    @include body;
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
    }
  }

  &__item-deposit {
    font-weight: 400;

    &--bold {
      font-weight: 700;
    }
  }

  &__item-note {
    @include small;
    & {
      background: $ra-light-blue;
      padding: 0.5rem 0.75rem;
      margin-top: 1rem;
      color: $ra-black;
    }
  }

  &__continue {
    @include small;
    & {
      color: $ra-blue;
      text-decoration: underline;
    }
  }

  &__summary {
    position: relative;
    width: 100%;
    background: $ra-lightest-grey;
    padding: 2rem 1rem;

    @media screen and (min-width: $sm) {
      padding: 2rem 1rem;
    }

    @media screen and (min-width: $md) {
      position: fixed;
      width: 20rem;
      min-height: 100vh;
      right: 0;
      top: 3rem;
      padding: 7rem 2rem 2rem;
    }
  }

  &__summary-title {
    @include h4;
    & {
      color: $ra-black;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  &__summary-total,
  &__summary-deposit {
    @include body;
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 0.5rem;

      span {
        color: $ra-black;

        @media screen and (min-width: $md) {
          line-height: 1.75rem;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &__summary-deposit {
    margin-bottom: 2rem;

    span {
      font-weight: 700;
    }
  }

  &__summary-secure {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 1.5rem 0;

    @media screen and (min-width: $md) {
      margin: 1rem 0;
    }
  }

  &__summary-payments {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;

    @media screen and (min-width: $md) {
      gap: 1rem;
    }
  }
  &__sold-out {
    width: 100%;
    min-height: 100vh;
    background: $ra-white;
    @include grid(12, 1fr);

    &__sold-out &__content {
      grid-column: 1 / -1;
      display: grid;
      @include grid(12, 1fr);
      & {
        padding-top: 1rem;
        flex-direction: initial;

        @media screen and (min-width: $md) {
          padding-top: 4rem;
        }
      }
    }

    .basket-page__content {
      grid-column: 1 / -1;
      @include grid(12, 1fr);
      & {
        padding-top: 1rem;
      }
      @media screen and (min-width: $md) {
        padding-top: 4rem;
      }
    }

    .basket-page__main {
      grid-column: 1 / span 12;
      padding: 2rem 1rem;

      @media screen and (min-width: $sm) {
        grid-column: 3 / span 8;
        padding: 2rem 1.5rem;
      }

      @media screen and (min-width: $md) {
        grid-column: 4 / span 12;
        padding: 0 1rem;
      }
    }
  }

  &__empty {
    width: 100%;
    @include grid(12, 1fr);
  }

  &__empty-message {
    grid-column: 1 / -1;
    height: 4rem;
    background: $ra-light-grey;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 700;
      line-height: 1.2rem;
      color: $ra-black;
    }
  }

  &__empty-cta {
    grid-column: 1 / span 12;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1rem 0;

    @media screen and (min-width: $md) {
      grid-column: 7 / span 6;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
}
