@import '../../../lib.scss';

.event-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  transition: transform $transition-time;
  border-bottom: 2px solid $ra-light-grey;
  &:hover {
    transform: translateY(-0.4rem);
  }
  &__image {
    position: relative;
    aspect-ratio: 4/3;
    background-color: $ra-light-grey;
    img {
      object-fit: cover;
      aspect-ratio: 4/3;
      height: 100%;
    }
  }
  &--16x9 &__image {
    aspect-ratio: 16/9;
    img {
      aspect-ratio: 16/9;
    }
  }
  &__free {
    font-weight: bold;
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $ra-white;
    color: $ra-blue;
  }
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $ra-black;
    padding: 0.25rem 0.5rem;
    @include body;
    & {
      color: $ra-white;
      line-height: 1rem;
      font-weight: bold;
    }
  }
  &__label {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__video {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    background-color: rgba($color: #ffffff, $alpha: 0.8);

    &::after {
      content: '';
      position: absolute;
      height: 24px;
      width: 24px;
      top: 16px;
      left: 17px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 11 0 21.392V.608L18 11Z' fill='%232A2A2A'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &__title {
    @include h4;
    & {
      margin-top: 0.5rem;
    }
  }
  &__link {
    color: $ra-black;
    text-decoration: none;
    display: inline;
    line-height: 2rem;
    &:hover {
      border-bottom: 4px solid $ra-blue;
      color: $ra-blue;
    }
    &:focus-visible {
      display: inline-flex;
      @include linkFocus;
      &:hover {
        border-bottom: none;
        color: $ra-black;
      }
    }
    @media screen and (min-width: $md) {
      line-height: 2.2rem;
    }
  }
  &__subtitle {
    margin-top: 0.5rem;
    @include h5;
    & {
      color: $ra-black;
    }
  }
  &__date,
  &__status,
  &__time {
    @include body;
    & {
      margin-top: 0.5rem;
      color: $ra-black;
    }
  }
  &__content {
    padding-bottom: 0.5rem;
    margin-bottom: auto;
  }
  &__status {
    font-weight: $bold;
  }
}
