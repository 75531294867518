@import '../../../lib.scss';

.image-viewer {
  width: 100%;
  height: 100%;

  &__thumbnail {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $ra-black;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__fullscreen-content {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      max-width: 90vw;
      max-height: 90vh;
      object-fit: contain;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: $ra-white;
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
  }
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background: rgba(42, 42, 42, 0.7);
  padding: 10px;
  border-radius: 20px;
  z-index: 10000;

  input[type='range'] {
    margin: 0 10px;
  }
}

.zoom-control-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: $ra-white;
  display: flex;
  align-items: center;
  justify-content: center;
}
