@import '../../lib.scss';

.hero-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  &--open {
    height: 100%;
  }

  &__grid {
    height: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start;

    @media screen and (min-width: $sm) {
      @include grid(12, 1fr);
      padding: 0;
    }
  }

  &__sidebar {
    @media screen and (min-width: $sm) {
      position: absolute;
      top: 0;
      left: auto;
      right: -2rem;
      width: 2rem;
    }

    @media screen and (min-width: $lg) {
      right: 2rem;
    }
  }

  &__sidebar-wrapper {
    @media screen and (min-width: $sm) {
      position: relative;
    }
  }

  &__btn {
    display: block;
    position: relative;
    background-color: $ra-black;
    width: 2rem;
    height: 2rem;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon--close {
      width: 1rem;
    }
    .icon--caption-copyright {
      height: 1.3rem;
    }
    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      @include buttonFocus;
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    display: inline;
    position: absolute;
    height: 2rem;
    top: 9rem;
    left: 0.25rem;
    transform-origin: 0 0;
    transform: rotate(270deg);
    transform-origin: 0 0;
    font-size: 1rem;
    font-weight: $bold;
    overflow: hidden;
    white-space: nowrap;
  }

  &__content-wrapper {
    overflow-y: hidden;
    background-color: $ra-white;
    opacity: 0.9;
    max-height: calc(100% - 1.5rem);
    overflow-y: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    @media screen and (min-width: $sm) {
      padding: 2rem 2rem 3rem 2rem;
      grid-column: 6/-1;
    }
    @media screen and (min-width: $md) {
      position: relative;
      max-height: calc(100% - 1.5rem);
      grid-column: 9/-1;
    }
    @media screen and (min-width: $lg) {
      max-height: calc(100% - 1rem);
    }
  }

  &__content {
    overflow-y: scroll;
    width: calc(100% - 2.5rem);
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;
      scrollbar-width: none; /* Firefox */
    }
  }

  &__title {
    display: block;
    font-family: $font;
    font-style: normal;
    font-weight: $bold;
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__body {
    display: none;

    @media screen and (min-width: $sm) {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  &__copyright {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
}
