@import '../../../lib.scss';

.artist-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  transition: transform $transition-time;
  background-color: $ra-white;
  &:hover {
    transform: translateY(-0.4rem);
  }
  &__image {
    position: relative;
    aspect-ratio: 16/9;
    background-color: $ra-light-grey;
    .image {
      object-fit: cover;
      aspect-ratio: 16/9;
    }
    .placeholder-image {
      aspect-ratio: 16/9;
    }
  }
  &__title {
    @include h4;
    & {
      margin-top: 0.5rem;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__subtitle {
    @include body;
    & {
      margin-top: 0.5rem;
      text-transform: capitalize;
    }
  }
  & {
    padding-bottom: 0.5rem;
    border-bottom: 2px solid $ra-light-grey;
  }
}
