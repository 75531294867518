@import '../../../../lib.scss';

.explorer-filter-dropdown {
  line-height: 1rem;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $summer-grey;
  border-radius: 0;

  &:last-child {
    border-bottom: none;
  }

  @media screen and (min-width: $md) {
    margin: 0.5rem;
    width: calc(33.333% - 1rem);
    border-bottom: none;
    border-radius: 1rem;
  }

  &--active {
    background-color: $ra-light-grey;
    border-radius: 1rem 1rem 0 0;

    @media screen and (min-width: $md) {
      background-color: transparent;
    }

    .explorer-filter-dropdown__title {
      background: $ra-light-grey;
      border-radius: 1rem 1rem 0 0;

      @media screen and (min-width: $md) {
        border-radius: 1rem;
      }
    }

    &::before {
      display: none;

      @media screen and (min-width: $md) {
        display: block;
      }
    }

    & + .explorer-filter-dropdown {
      &::before {
        display: none;

        @media screen and (min-width: $md) {
          display: block;
        }
      }
    }
  }

  & + .explorer-filter-dropdown {
    &::before {
      content: '';
      position: absolute;
      background-color: $ra-black;
      width: 1px;
      height: 2.5rem;
      left: -0.5rem;
      top: 50%;
      transform: translateY(-50%);
      display: none;

      @media screen and (min-width: $md) {
        display: block;
      }
    }
  }

  &__selected-count {
    z-index: 3;
    position: absolute;
    right: 3rem;
    top: 0.7rem;
    width: 1.5rem;
    height: 1.5rem;
    background: $ra-black;
    color: $ra-white;
    text-align: center;
    padding: 0.25rem;
    line-height: 1rem;
    border-radius: 1rem;
  }

  &__mobile-button,
  &__title {
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: $bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    cursor: pointer;
    border-radius: 1rem;
    position: relative;
    z-index: 3;
    @include buttonFocus;

    &:hover {
      background: $ra-light-grey;
    }
  }

  &__content {
    background: $ra-light-grey;
    padding: 1rem 2rem 1rem;
    z-index: 2;
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    display: none;
    border-radius: 0 0 1rem 1rem;

    &--show {
      display: block;
      position: relative;
      left: initial;
      top: initial;
      width: 100%;
      height: initial;
      overflow: initial;

      @media screen and (min-width: $md) {
        margin: 1rem 0;
        position: absolute;
        top: 3.5rem;
        left: -0.5rem;
        width: calc(100% + 1rem);
        margin: 0.5rem 0;
      }
    }

    @media screen and (min-width: $md) {
      padding: 2rem 2rem 1rem;
      border-radius: 1rem;

      &--2cols {
        width: calc(200% + 2rem);
      }
    }
  }

  &__checkboxes {
    .checkbox {
      margin: 1rem 0;
      .checkbox__input {
        &.checkbox__input--theme--ra-light-blue {
          border-color: $ra-black;

          &:checked {
            background-color: $ra-black;
          }
        }
      }
    }
  }

  &--mobile {
    background-color: transparent;

    &.explorer-filter-dropdown--active {
      background-color: $ra-light-grey;
      border-radius: 1rem;

      .explorer-filter-dropdown__title {
        background: $ra-light-grey;
        border-radius: 1rem 1rem 0 0;
      }
    }

    .explorer-filter-dropdown__title {
      background: transparent;
      border-radius: 1rem;
    }

    .explorer-filter-dropdown__content {
      display: none;

      &--show {
        display: block;
      }
    }

    &:not(.explorer-filter-dropdown--active) {
      .explorer-filter-dropdown__title {
        background: transparent;
        border-radius: 1rem;
      }
    }

    &:not(.explorer-filter-dropdown--active) .explorer-filter-dropdown__title:hover {
      background: transparent;
    }
  }
}
