@import '../../lib.scss';

.home-hero {
  @media screen and (min-width: $sm) {
    margin-top: 1rem;
  }
  @media screen and (min-width: $xl) {
    margin-top: 2rem;
  }
  &__container {
    @include container;
  }

  &__promo {
    position: relative;
    &__image {
      z-index: 0;
      aspect-ratio: 1/1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-left: -1rem;
      width: calc(100% + 2rem);
      max-height: 590px;
      @media screen and (min-width: $sm) {
        aspect-ratio: 16/9;
        margin-left: 0;
        width: 100%;
      }
    }
    &__content {
      padding: 1rem;
      margin-top: -1rem;
      margin-right: -1rem;
      z-index: 1;
      position: relative;
      cursor: pointer;
      @media screen and (min-width: $sm) {
        margin-top: none;
        position: absolute;
        width: calc(50% + 2rem);
        right: 0;
        bottom: -7rem;
        right: -2rem;
        padding-right: 3rem;
      }
      @media screen and (min-width: $md) {
        padding: 2rem 3rem 2rem 2rem;
        width: calc(40% + 2rem);
        right: -2rem;
        bottom: -3rem;
      }
      @media screen and (min-width: $lg) {
        width: calc(40% + 5rem);
        right: -5rem;
        padding-right: 6rem;
      }
    }
    &__title {
      @include h2;
    }
    &__subtitle {
      @include h5;
    }

    &__date,
    &__time {
      @include body;
    }
    &__date {
      margin-top: 0.5rem;
    }
    .primary-cta {
      margin-top: 1rem;
    }
  }
  &__visit {
    margin-top: -18rem;
    padding-top: 18rem;
    margin-left: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    @media screen and (min-width: $sm) {
      margin-top: -4rem;
      padding-top: 4rem;
      padding-left: 2rem;
      margin-left: -2rem;
    }
    @media screen and (min-width: $lg) {
      padding-left: 5rem;
      margin-left: -5rem;
    }
    &__title {
      font-family: $intro-font;
      font-style: normal;
      font-weight: $bold;
      font-size: 3.75rem;
      line-height: 100%;
      margin-top: 1rem;
      display: flex;
      @media screen and (min-width: $sm) {
        max-width: calc(50% - 1rem);
      }
      @media screen and (min-width: $md) {
        max-width: calc(60% - 1rem);
      }
      svg {
        max-width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
    &__grid {
      padding: 1rem 0;
      @media screen and (min-width: $md) {
        @include grid(12, 1fr);
        padding: 2rem 0;
      }
    }
    &__info-items {
      grid-column: 1 / span 4;
      @media screen and (max-width: $sm) {
        margin-bottom: 2rem;
      }
      svg {
        float: left;
        width: 1rem;
        height: 1.2rem;
      }
      .info-items {
        &__title,
        &__description {
          margin-left: 2rem;
        }
        &__title {
          @include h6;
          & {
            font-weight: $bold;
          }
        }
        &__item {
          margin-bottom: 1rem;
        }
        &__location {
          color: inherit;
          text-decoration: none;
          border-bottom: 1px solid;
          transition: border-bottom $transition-time;
          &:hover {
            border-bottom: 3px solid;
          }
          &:focus-visible {
            display: inline-flex;
            @include linkFocus;
            &:hover {
              border-bottom: none;
              color: $ra-black;
            }
          }
        }
      }
    }
    &__about {
      grid-column: 5 / 12;
    }
    &.bg--ra-light-blue {
      .tertiary-cta,
      .tertiary-cta:hover {
        color: $ra-black;
        svg {
          fill: $ra-black;
        }
        path,
        line {
          stroke: $ra-black;
        }
      }
    }

    &__description {
      font-family: $intro-font;
      font-style: normal;
      font-weight: $normal;
      font-size: 1.5rem;
      line-height: 1.7rem;
      margin-bottom: 2rem;
      @media screen and (min-width: $sm) {
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
    }
  }
}
