@import '../../lib.scss';

.promo {
  margin-top: 5rem;
  &__wrapper {
    overflow-x: hidden;
  }
  &__container {
    @media screen and (min-width: $sm) {
      @include container;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 3rem 1fr 1fr;
    @media screen and (min-width: $sm) {
      @include grid(12, 1fr);
      grid-template-rows: 9rem 1fr 2rem;
    }
  }
  &__image {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
    z-index: 1;
    margin-right: 1rem;
    margin-left: 0;
    aspect-ratio: 16 / 9;
    @media screen and (min-width: $sm) {
      grid-column: 1 / span 6;
      grid-row: 2 / 4;
      z-index: 1;
      margin: auto 0 0 0;
    }
  }

  &--right &__image {
    margin-left: 1rem;
    margin-right: 0;
    @media screen and (min-width: $sm) {
      margin: auto 0 0 0;
      grid-column: 7 / span 6;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    grid-row: 2 / 4;
    margin-left: 1rem;
    margin-right: 0;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 100%;
      width: 50%;
      transform: translateX(50%);
      z-index: -1;
      background-color: inherit;
    }

    @media screen and (min-width: $sm) {
      @include grid;
      grid-column: 3 / span 10;
      grid-row: 1 / 3;
      grid-template-areas: '. . . . . content content content content content content .';
      margin: 0;
    }

    @media screen and (min-width: $md) {
      &::after {
        content: none;
      }
    }
  }

  &--right &__content {
    margin-right: 1rem;
    margin-left: 0;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: initial;
      height: 100%;
      width: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background-color: inherit;
    }
    @media screen and (min-width: $sm) {
      grid-template-areas: '. content content content content content content . . . . . . ';
      grid-column: 1 / span 10;
      margin: 0;
    }
    @media screen and (min-width: $md) {
      &::after {
        content: none;
      }
    }
  }

  &__inner {
    padding: 1rem;
    padding-top: calc(56.25% - 2rem);
    @media screen and (min-width: $sm) {
      margin-top: initial;
      grid-area: content;
      padding: 3rem 0;
    }
  }

  &--right &__inner {
    @media screen and (min-width: $sm) {
      padding: 3rem 0;
    }
  }
  &__title {
    @include h2;
  }
  &__description {
    margin-top: 1rem;
    @include intro-text;
  }
  &__cta {
    margin-top: 1rem;
  }
}
