@import '../../../lib.scss';

.image-popup {
  position: relative;
  min-height: 100vh;
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
  &__wrapper {
    @include container;
    & {
      @include grid;
    }
    & {
      padding-top: 1rem;
      padding-bottom: 5.5rem;
      position: relative;
    }
    @media screen and (min-width: $sm) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      row-gap: 0;
    }
    @media screen and (min-width: $md) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      row-gap: 0;
      min-height: 100vh;
    }
    @media screen and (min-width: $lg) {
      position: static;
    }
  }

  &__image-wrapper {
    grid-column: 1 / span 12;
    @media screen and (min-width: $sm) {
      grid-column: 2 / span 10;
    }
    @media screen and (min-width: $md) {
      align-self: center;
      grid-column: 1 / span 6;
      .image {
        height: calc(100vh - 3rem);
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        margin: 0 auto;
      }
    }
    @media screen and (min-width: $lg) {
      grid-column: 1 / span 7;
    }
  }

  &__info-wrapper {
    grid-column: 1 / span 12;
    margin-top: 0.5rem;
    @media screen and (min-width: $sm) {
      grid-column: 2 / span 11;
      @include grid(11);
      & {
        margin-top: 1rem;
      }
    }
    @media screen and (min-width: $md) {
      grid-column: 7 / span 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0;
    }
    @media screen and (min-width: $lg) {
      grid-column: 8 / span 4;
    }
  }

  &__text-wrapper {
    @media screen and (min-width: $sm) {
      grid-column: 1 / span 8;
    }
  }

  &__title {
    @include h4;
  }

  &__desc,
  &__subtitle {
    margin-top: 0.5rem;
    @include body;
  }

  &__details {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 2px solid $ra-light-grey;
    @include body;
    @media screen and (min-width: $md) {
      margin-top: 1rem;
      padding-top: 1rem;
    }
  }

  &__controls {
    background-color: $ra-light-grey;
    margin: 0 -1rem 0;
    padding: 0.5rem 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__arrows {
    line-height: 0;
    @media screen and (min-width: $sm) {
      grid-column: 9 / span 3;
      margin-left: auto;
      padding-top: 3rem;
      transform: translateX(0.5rem);
    }

    @media screen and (min-width: $md) {
      position: absolute;
      right: 2rem;
      grid-column: auto;
      padding-top: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (min-width: $lg) {
      right: 1rem;
    }
    @media screen and (min-width: $xl) {
      right: 2rem;
    }
  }

  &__button {
    width: 48px;
    height: 48px;
    font-size: 0;
    line-height: 0;
    border-radius: 50%;
    @include buttonFocus;
    &:not([disabled]) {
      background-color: $ra-black;
      &:hover {
        background-color: $ra-blue;
      }
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: $ra-white;
    }

    &:disabled {
      opacity: 0.2;
      cursor: auto;
      svg {
        fill: $ra-black;
      }
    }

    &--prev {
      margin-right: 1rem;
      @media screen and (min-width: $md) {
        margin-right: 1.5rem;
      }
    }

    &--close {
      @media screen and (min-width: $sm) {
        position: absolute;
        right: 1.5rem;
        top: 1rem;
      }
      @media screen and (min-width: $md) {
        top: 1.5rem;
        right: 2rem;
      }
      @media screen and (min-width: $lg) {
        right: 1rem;
      }
      @media screen and (min-width: $xl) {
        right: 2rem;
      }
    }
  }
}
