@import '../../lib.scss';

.newsletter {
  margin-top: 5rem;

  &__container {
    @include container;
  }

  &__grid {
    padding: 1rem;

    @media screen and (min-width: $sm) {
      @include grid(12);
      padding: 2rem 0 2rem 0;
    }

    @media screen and (min-width: $md) {
      padding: 3rem 0 3rem 0;
    }
  }

  &__icon {
    width: 3.5rem;
    height: 100%;

    @media screen and (min-width: $sm) {
      grid-column: 1 / span 4;
      margin: auto;
      width: 70%;
    }

    @media screen and (min-width: $lg) {
      width: 65%;
    }
  }

  .icon--newsletter {
    width: 3.5rem;
    height: auto;

    @media screen and (min-width: $sm) {
      grid-column: 1 / span 4;
      margin: auto;
      margin-top: 0;
      width: 70%;
    }

    @media screen and (min-width: $lg) {
      width: 65%;
    }
  }

  &__content {
    grid-column: 1 / span 4;

    @media screen and (min-width: $sm) {
      grid-column: 5 / span 8;
      padding-right: 2rem;
    }

    @media screen and (min-width: $md) {
      grid-column: 5 / span 6;
      padding: 0;
    }
  }

  &__title {
    @include h2;
  }

  &__description {
    margin-top: 1rem;

    a {
      @include linkInline;
      & {
        display: inline;
      }
      &:focus-visible {
        @include linkFocus;
        text-decoration: none;
        border-bottom: none;
      }
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 0;
    > div + div {
      margin-top: 2rem;
    }
    > fieldset {
      margin-top: 2rem;
    }

    @media screen and (min-width: $sm) {
      > div + div {
        margin-top: 1rem;
      }
      > fieldset {
        margin-top: 1rem;
      }
    }

    .form-field__label {
      font-weight: $bold;
    }

    .form-field__input {
      border: 1px solid $ra-black;
      margin-top: 0.5rem;

      &[type='date'] {
        text-transform: uppercase;
        @media screen and (min-width: $sm) {
          width: calc(50% - 1rem);
        }
      }
    }
  }

  &__two-sections {
    display: flex;
    flex-direction: column;
    .form-field:last-of-type {
      margin-top: 2rem;
    }

    @media screen and (min-width: $sm) {
      width: 100%;
      flex-direction: row;

      .form-field {
        flex-grow: 1;
        &:first-of-type {
          margin-right: 2rem;
        }
        &:last-of-type {
          margin-top: 0;
        }
      }
    }
  }

  &__thank-you {
    display: flex;
    margin-top: 1.5rem;
    > svg {
      margin-right: 0.9rem;
    }
    > p {
      font-weight: $bold;
    }
    @media screen and (min-width: $sm) {
      margin-top: 1rem;
      > svg {
        margin-right: 0.9rem;
      }
    }

    @media screen and (min-width: $md) {
      grid-column: 1 / span 6;
    }

    &--theme--ra-blue {
      path {
        fill: $ra-light-blue;
      }
    }

    &--theme--ra-light-blue {
      path {
        fill: $ra-blue;
      }
    }

    &--theme--ra-pink {
      path {
        fill: $ra-light-pink;
      }
    }

    &--theme--ra-light-pink {
      path {
        fill: $ra-pink;
      }
    }

    &--theme--ra-purple {
      path {
        fill: $ra-light-purple;
      }
    }

    &--theme--ra-light-purple {
      path {
        fill: $ra-purple;
      }
    }

    &--theme--ra-green {
      path {
        fill: $ra-light-green;
      }
    }

    &--theme--ra-light-green {
      path {
        fill: $ra-green;
      }
    }

    .icon {
      min-width: 1.5rem;
      height: 1.5rem;

      @media screen and (min-width: $sm) {
        min-width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  .primary-cta {
    margin: 0 0 0 auto;
    display: block;
  }

  &__fieldset-desc {
    margin-top: 1rem;

    @media screen and (min-width: $sm) {
      margin-top: 0.5rem;
    }
  }

  &__legend {
    font-weight: $bold;
    padding: 0;
    margin: 0;

    &--required::after {
      content: '*';
    }
  }

  &__checkbox-group {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    .checkbox {
      margin: 0.5rem 0;
    }

    @media screen and (min-width: $sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__error {
    margin-top: 1rem;
    font-weight: $bold;
    color: white;
    display: flex;
    align-items: flex-start;
    background-color: $ra-pink;
    padding: 0.5rem 1rem;

    @media screen and (min-width: $sm) {
      margin: 2rem 0;
    }

    .icon {
      flex-shrink: 0;
      width: 24px;
      height: 67px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: -0.5rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .error-message {
      flex: 1;
    }

    &--ra-blue {
      color: $ra-light-blue;
    }

    &--ra-light-blue {
      color: $ra-blue;
    }

    &--ra-pink {
      color: $ra-light-pink;
    }

    &--ra-light-pink {
      color: $ra-pink;
    }

    &--ra-purple {
      color: $ra-light-purple;
    }

    &--ra-light-purple {
      color: $ra-purple;
    }

    &--ra-green {
      color: $ra-light-green;
    }

    &--ra-light-green {
      color: $ra-green;
    }
  }
}
