@import '../../lib.scss';

.error {
  margin: 7rem 0;
  &__container {
    @include container;
  }
  &__title {
    @include h3;
    & {
      margin-bottom: 2rem;
    }
  }
  &__description {
    @include h5;
  }
}
