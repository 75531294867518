@import '../../../lib.scss';

.whats-on-no-result {
  margin-top: -2rem;
  padding-bottom: 4.5rem;

  @media screen and (min-width: $md) {
    padding-bottom: 0;
  }

  &__listing-info {
    span {
      font-weight: $bold;
    }
  }

  &__heading {
    @include h2;
    & {
      margin-top: 1rem;
    }
  }

  &__desc {
    @include intro-text;
    & {
      margin-top: 1rem;
    }
  }

  button {
    margin-top: 1rem;
  }
}
