@import '../../../lib';

.category-card {
  display: flex;
  padding-bottom: 0.5rem;
  cursor: pointer;

  &__image {
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 8rem;
    min-width: 8rem;
    margin-right: 1rem;
  }

  &__title {
    @include h4;
    & {
      align-self: center;
      flex: 1;
    }
  }

  &__anchor {
    display: inline;
    color: inherit;
    text-decoration: none;
    &:hover {
      border-bottom: 4px solid $ra-blue;
      color: $ra-blue;
    }
    &:focus-visible {
      display: inline-flex;
      @include linkFocus;
      &:hover {
        border-bottom: none;
        color: $ra-black;
      }
    }
  }
}
