@import '../../lib.scss';

.artists-filter {
  margin-top: 5rem;
  &__filters {
    padding: 3rem 0 2rem 0;
    @media screen and (min-width: $sm) {
      padding: 3rem 0 4rem 0;
    }
  }
  &__container {
    @include container;
  }
  &__title {
    @include h2;
    & {
      margin-bottom: 1rem;
    }
  }
  &__grid {
    @media screen and (min-width: $sm) {
      @include grid(12, 1fr);
      & {
        row-gap: 0;
      }
    }
  }
  &__search {
    margin-bottom: 1rem;
    grid-column: 1/9;
    @media screen and (min-width: $sm) {
      margin-bottom: 0;
    }
    .form-field__input {
      height: 4rem;
      border-radius: 1.5rem;
      border: none;
      padding-left: 2rem;
      &:focus-visible {
        border: none;
      }
    }
    .form-field--inline-button button {
      height: 4rem;
      border-radius: 0 1.5rem 1.5rem 0;
      padding: 1rem;
      right: 0;
      width: 4rem;
      line-height: 3rem;
      @include theme;
    }
  }
  &__dropdown {
    grid-column: 9/13;
  }
  .dropdown-checkbox {
    &__title {
      height: 3rem;
      line-height: 2rem;
      border: none;
    }
    &__options {
      border: none;
    }
  }
  &__tags {
    grid-column: 1 / 13;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .tag {
      text-transform: capitalize;
    }
  }
  &__pagination {
    margin-top: 1rem;
  }
  &__results {
    @include container;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 2rem 0;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__no-results {
    margin: 3rem 0;
    &__listing-info {
      @include body;
    }
    &__heading {
      @include h2;
      & {
        margin-top: 1rem;
      }
    }

    &__desc {
      @include intro-text;
      & {
        margin-top: 1rem;
      }
    }
  }
}
