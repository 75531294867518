@import '../../lib.scss';

// FIXME: This should be a component
@mixin accordion {
  @media screen and (max-width: ($sm - 1px)) {
    padding-bottom: 0;
    > h3 {
      padding: 1rem 0;
      margin: 0;
      position: relative;
      &.accordion__handle {
        display: flex;
        cursor: pointer;
        &:focus-visible {
          @include linkFocus;
        }
      }
      svg {
        right: 0;
        top: 53%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
    > ul {
      max-height: 0;
      overflow: hidden;
    }
    > ul {
      transition: max-height $transition-time;
    }
    > ul.open {
      max-height: 1000px;
      padding-bottom: 2rem;
    }
    li {
      display: block;
    }
  }
}

.footer {
  padding: 5rem 0 0.5rem 0;
  background-color: $ra-black;
  color: $ra-white;
  margin-top: 5rem;
  @media screen and (min-width: $sm) {
    padding: 5rem 0 1rem 0;
  }
  &__container {
    position: relative;
    @include container;
  }
  h3 {
    @include h5;
    & {
      margin-bottom: 1rem;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  &__back-to-top {
    position: absolute;
    right: 1rem;
    top: -4rem;
    @media screen and (min-width: $md) {
      right: 2rem;
    }
    @media screen and (min-width: $lg) {
      right: 5rem;
    }
    .tertiary-cta__arrow {
      transform: rotate(270deg);
      top: 0.2rem;
      right: -1.5rem;
      transition: top $transition-time;
    }

    &:hover {
      .tertiary-cta__arrow {
        top: 0rem;
        right: -1.5rem;
      }
    }
  }

  &__social {
    margin: 2rem 0 1rem;

    &__links {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      li {
        display: inline-flex;
        margin: 0 1rem 0 0;
      }
      a {
        padding: 0;
        @include buttonFocus;
      }
      a:hover {
        svg.icon path {
          fill: $ra-light-blue;
        }
      }

      @media screen and (min-width: $sm) {
        margin-top: 0.5rem;
      }
    }

    @media screen and (min-width: $sm) {
      display: block;
      margin: 2rem 0 0;
    }
  }

  &__about__links {
    @media screen and (min-width: $sm) {
      padding-bottom: 1rem;
    }
  }

  &__about__link:first-child a {
    @media screen and (min-width: $sm) {
      padding-top: 0;
    }
  }

  &__where__links {
    @media screen and (min-width: $sm) {
      border-bottom: 0;
    }
  }

  &__social-icon {
    > svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &__context {
    &__grid {
      @media screen and (min-width: $sm) {
        @include grid(12, 1fr);
      }
      > div {
        border-bottom: 2px solid $ra-white;
        @media screen and (min-width: $sm) {
          margin-bottom: 1rem;
        }
      }
    }
    &__about {
      a {
        display: inline-block;
        @include underline-link;
        & {
          padding: 0.5rem 0;
        }
        &:focus-visible {
          @include linkFocus;
        }
      }
      @include accordion;
    }
    &__where {
      h4 {
        @include h6;
      }
      h4,
      p {
        margin-left: 2.5rem;
      }
      &__item {
        margin-bottom: 1rem;
        .footer__context__connect {
          display: none;
          @media screen and (min-width: $sm) {
            display: block;
          }
        }
      }
      .icon {
        float: left;
      }
      a {
        &:hover {
          svg path {
            fill: #d4edf5;
          }
        }
      }
      @include accordion;
    }
    &__connect {
      @include accordion;

      @media screen and (min-width: $sm) {
        margin-top: 2rem;
        padding-bottom: 1rem;
        display: none;
      }
    }

    &__newsletter {
      margin-bottom: 0.5rem;
      .form-field {
        margin-top: 1rem;
      }
      &__cta {
        margin: 1rem 0;
        display: flex;
        justify-content: flex-end;
      }
      a {
        @include linkInline;
        & {
          color: inherit;
        }
        &:focus-visible {
          @include linkFocus;
        }
      }
      @include accordion;
    }
    @media screen and (min-width: $sm) {
      &__about {
        grid-column: 1 / span 6;
      }
      &__where {
        grid-column: 7 / span 6;
      }
      &__newsletter {
        grid-column: 1 / span 12;
      }
    }
    @media screen and (min-width: $md) {
      &__about {
        grid-column: 1 / span 4;
      }
      &__where {
        grid-column: 5 / span 4;
      }
      &__newsletter {
        grid-column: 9 / span 4;
        &__cta {
          margin-bottom: 0;
        }
      }
    }
  }

  &__contact {
    &__links {
      a {
        @include underline-link;
        & {
          display: inline-block;
          padding: 0.5rem 0;
        }
        &:focus-visible {
          @include linkFocus;
        }
      }
    }

    &__link:first-child a {
      @media screen and (min-width: $sm) {
        padding-top: 0;
      }
    }
  }

  &__utility {
    margin-top: 1rem;
    @media screen and (max-width: ($sm - 1px)) {
      margin-top: -0.5rem;
    }
    &__links {
      margin-bottom: 1rem;
      @media screen and (max-width: ($sm - 1px)) {
        border-bottom: 2px solid $ra-white;
      }
      li {
        display: inline-block;
        margin-right: 2rem;
      }
      a {
        display: inline-block;
        padding: 0.5rem 0;
        @include underline-link;
        &:focus-visible {
          @include linkFocus;
        }
      }
      @include accordion;
    }
  }
  &__signup--success {
    position: relative;
    font-weight: $bold;
    margin: 2rem;
    @media screen and (min-width: $sm) {
      margin: 2rem 0 2rem 2rem;
    }
    &::before {
      display: inline-block;
      position: absolute;
      top: 3px;
      left: -2rem;
      content: '';
      width: 24px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='15' fill='currentColor'%3E%3Cpath fill='%23fff' d='M1.705 5.46s1.366.556 1.676 1.405l.437 1.136c.254.612.587 1.382 1.12 2.184.27.405.587.818.968 1.255l.294.325.064.072h.015s.032.047.056.04c.032-.017.016-.017.056-.04.27-.167.524-.374.794-.564.524-.39 1.04-.81 1.532-1.255 1.986-1.77 3.67-3.899 4.876-5.535a89.48 89.48 0 0 0 1.962-2.787S16.659.56 17.897.926c0 0 .683.698-.246 2.294-.238.397-1.111 1.763-2.422 3.542-1.31 1.771-3.097 3.955-5.233 5.773-.532.453-1.08.89-1.644 1.287-.135.103-.278.198-.42.294l-.755.492c-.516.31-1.04.397-1.565.23-.246-.047-.444-.278-.65-.46-.207-.19-.422-.366-.62-.564l-.365-.405-.302-.341c-.39-.453-.747-.914-1.048-1.366-.62-.906-.985-1.732-1.223-2.272-.239-.547-.35-.85-.35-.85S.8 8.066.768 7.422c-.031-.643.135-1.405.945-1.953l-.008-.008Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__signup--error {
    margin-top: 1rem;
    font-weight: $bold;
    font-size: 0.9rem;
    display: flex;
    align-items: flex-start;
    background-color: $ra-pink;
    color: $ra-white;
    padding: 0.5rem 1rem;

    .icon {
      flex-shrink: 0;
      width: 24px;
      height: 70px;
      margin-right: 0.5rem;
      margin-top: 2px;
    }
  }
}
