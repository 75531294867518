@import '../../lib.scss';

.article-hero {
  overflow-x: hidden;
  @media screen and (min-width: $md) {
    overflow-x: unset;
  }
  @media screen and (min-width: $xl) {
    margin-top: 2rem;
  }
  &__container {
    position: relative;
    z-index: 2;
    @include container;
  }

  &__image-container {
    position: relative;
    width: 100%;

    @media screen and (min-width: $md) {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      width: auto;
    }

    @media screen and (min-width: $lg) {
      margin-top: 1rem;
      margin-left: 1rem;
      width: auto;
    }

    @media screen and (min-width: $xl) {
      margin-top: 1rem;
      margin-left: 14.6rem;
      width: auto;
      max-width: 1332px;
    }
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: calc(100% + 2rem);
    grid-column: 1/-1;
    margin-left: -1rem;
    margin-right: -1rem;
    max-height: 590px;
    @media screen and (min-width: $sm) {
      aspect-ratio: 126/59;
      margin-right: 0rem;
      margin-left: -2rem;
    }

    @media screen and (min-width: $md) {
      width: calc(100% + 1.5rem);
      margin-left: -1.5rem;
    }

    @media screen and (min-width: $lg) {
      width: 100%;
      margin-left: -4rem;
    }

    & img {
      height: 100%;
      object-fit: cover;
    }
  }
  &__grid {
    position: relative;
    @include grid(4, 1fr);
    & {
      row-gap: 0;
      margin: 0 -0.5rem;
    }
    @media screen and (min-width: $sm) {
      @include grid(12, 1fr);
      & {
        row-gap: 0 !important;
        margin: initial;
      }
    }
    @media screen and (min-width: $md) {
      margin: 0.5rem 0 0;
    }
    @media screen and (min-width: $lg) {
      margin: 1rem 0 0 0;
    }
  }

  &__text {
    position: relative;
    grid-column: 1/5;
    padding: 0.5rem 0.5rem 0 0.5rem;
    margin-top: -1rem;
    background-color: $ra-white;
    @media screen and (min-width: $sm) {
      grid-column: 1/12;
      margin-right: -2rem;
      margin-left: -1rem;
    }

    @media screen and (min-width: $md) {
      padding: 1rem 1rem 0 1rem;
      margin-top: -3.5rem;
      margin-right: 0;
      grid-column: 1/9;
      grid-row: 1/3;
    }

    @media screen and (min-width: $lg) {
      margin-top: -4.5rem;
    }

    &__title {
      @include h1;
      & {
        margin-bottom: 1rem;
      }
    }

    &__subtitle {
      @include h5;
    }

    &__date {
      @include body;
    }

    &__standfirst {
      @include intro-text;
      & {
        margin-top: 2rem;
      }
    }
  }
}

.article-hero + .rich-text {
  margin-top: 1.5rem;
}
