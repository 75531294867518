@import '../../../lib.scss';
button.primary-cta {
  background: transparent;
}
.primary-cta {
  @include ctaBase;
  color: $ra-black;
  min-width: 10rem;
  padding-right: 1rem;
  cursor: pointer;

  &--no-arrow {
    padding-right: 0;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: 10rem;
    height: 3.5rem;
  }
  &::before {
    background: url('./primary-background.svg') no-repeat center;
    opacity: 0;
    transition: opacity $transition-time;
    background-size: contain;
  }
  &::after {
    background: url('./primary-border.svg') no-repeat center;
    background-size: contain;
  }

  &--wide {
    width: 13rem;
    &::after,
    &::before {
      width: 13rem;
    }
  }

  &__text {
    position: relative;
    display: block;
    top: 5px;
    padding: 1.5rem;
    text-align: center;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 1.6rem;
      right: 0.3rem;
      width: 1rem;
      height: 1rem;
      background: url('./primary-arrow.svg') no-repeat center;
      transition: transform $transition-time;
    }
  }

  &:hover,
  &:focus,
  &--selected {
    &::before {
      opacity: 0.8;
    }
    .primary-cta__text {
      color: $ra-black;
      &::after {
        transform: translateX(6px);
        @media screen and (min-width: $sm) {
          transform: translateX(8px);
        }
      }
    }
  }

  &--white {
    color: $ra-white;
    &::before {
      background: url('./primary-background--light.svg') no-repeat center;
      background-size: contain;
    }
    &::after {
      background: url('./primary-border--light.svg') no-repeat center;
      background-size: contain;
    }
    .primary-cta__text::after {
      background: url('./primary-arrow--light.svg') no-repeat center;
    }
    &:hover,
    &:focus {
      color: $ra-black;
      .primary-cta__text::after {
        background: url('./primary-arrow.svg') no-repeat center;
      }
    }
    &.primary-cta--wide {
      &::before {
        background: url('./primary-background-wide--light.svg') no-repeat center;
        top: 12px;
      }
      &::after {
        background: url('./primary-border-wide--light.svg') no-repeat center;
      }
    }
  }

  &--no-arrow {
    .primary-cta__text::after {
      content: none;
    }
  }

  @include buttonFocus();
}
