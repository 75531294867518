@import '../../lib.scss';

.pull-quote {
  &__container {
    @include container;
    & {
      margin-top: 3rem;
    }
  }

  &__outer-grid {
    @include grid;
  }

  &__outer-column {
    grid-column: 1/12;
    @media screen and (min-width: $md) {
      grid-column: 1/8;
    }
  }

  &__grid {
    @include grid();
    & {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    &--2 {
      @media screen and (min-width: $sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &--1 {
      @media screen and (min-width: $sm) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  svg {
    width: 2.77rem;
    height: 2.5rem;
  }

  &__quotation {
    &--theme--ra-blue {
      path {
        fill: $ra-blue;
      }
    }
    &--theme--ra-light-blue {
      path {
        fill: $ra-light-blue;
      }
    }
    &--theme--ra-green {
      path {
        fill: $ra-green;
      }
    }
    &--theme--ra-light-green {
      path {
        fill: $ra-light-green;
      }
    }
    &--theme--ra-purple {
      path {
        fill: $ra-purple;
      }
    }
    &--theme--ra-light-purple {
      path {
        fill: $ra-light-purple;
      }
    }
    &--theme--ra-pink {
      path {
        fill: $ra-pink;
      }
    }
    &--theme--ra-light-pink {
      path {
        fill: $ra-light-pink;
      }
    }
  }

  &__cite {
    @include intro-text;
    & {
      margin-top: 0.5rem;
    }
  }

  &__name {
    @include h5;
    & {
      color: $ra-black;
      margin-top: 1rem;
    }
  }

  &__subtitle {
    @include h6;
    & {
      line-height: 1.5rem;
    }
  }
}
