@import '../../lib.scss';

.date-filter {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'filters'
    'picker';
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  @media screen and (min-width: $md) {
    padding: 1.25rem 1.5rem 1.5rem 1.5rem;

    grid-gap: 1rem;
    position: relative;
    display: flex;
    height: initial;
    padding: 0;
  }
  button {
    @include buttonFocus;
    & {
      cursor: pointer;
    }
  }
  &__filters {
    margin-top: 0.5rem;
    grid-area: filters;
    @media screen and (min-width: $md) {
      margin-top: 0;
      min-height: 170px;
      width: 50%;
      padding-bottom: 0;
      min-height: auto;
    }
    &__item {
      &:first-child .checkbox {
        margin-top: 0;
      }

      &:last-child .checkbox {
        margin-bottom: 0;
      }

      @media screen and (min-width: $md) {
        margin-top: 1rem;
      }
    }
    &__title {
      @include h2;
      & {
        color: $ra-black;
        margin-bottom: 1rem;
        text-align: center;
      }
      @media screen and (min-width: $sm) {
        color: $ra-light-blue;
        text-align: left;
      }
    }
    > ol {
      grid-template-areas:
        'today thisWeekend'
        'tomorrow nextWeek';
      grid-template-columns: 1fr 1fr;
    }

    #date-filter__legend {
      font-size: 0;
      text-indent: -9999px;
    }

    &__today {
      grid-area: today;
    }
    &__tomorrow {
      grid-area: tomorrow;
      margin-top: 0.875rem;
    }
    &__this-weekend {
      grid-area: thisWeekend;
      @media screen and (min-width: $sm) {
        margin-top: 0.875rem;
      }
    }
    &__next-week {
      grid-area: nextWeek;
      margin-top: 0.875rem;
    }

    &__today,
    &__tomorrow,
    &__this-weekend,
    &__next-week {
      &:focus-visible {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    &__calendar {
      margin-left: auto;
    }
  }
  &__picker {
    position: relative;
    grid-area: picker;
    @media screen and (min-width: $md) {
      width: 50%;
    }
  }
}
