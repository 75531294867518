@import '../../lib.scss';

.explorer-artwork {
  width: 100%;
  max-width: $width;
  margin: 0 auto;
  padding: 0 1rem;
  font-family: $font;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $md) {
    padding: 0 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5rem;
  }

  @media screen and (min-width: $lg) {
    padding: 0 5rem;
    max-width: calc($width + 10rem);
  }

  &__image_mobile {
    margin-bottom: 2rem;
  }

  &__mobile-header {
    margin-bottom: 1rem;
    margin-top: 2rem;

    &__category {
      @include h5;
      & {
        margin-bottom: 1.25rem;
        color: $ra-black;
        font-weight: 400;
      }
    }

    &__displayname {
      @include h3;
      & {
        margin-bottom: 1.25rem;
      }
    }

    &__gallery-description {
      display: flex;
      margin-bottom: 1.25rem;
      color: $summer-grey;
    }

    &__description {
      margin-bottom: 1.25rem;
      color: $summer-grey;
    }

    @media screen and (min-width: $md) {
      display: none;
    }
  }

  &__displayname {
    @include h3;
    & {
      margin-bottom: 1.25rem;
    }
  }

  &__gallery-description {
    display: flex;
    margin-bottom: 1.25rem;
    color: $summer-grey;
  }

  &__description {
    margin-bottom: 1.25rem;
    color: $summer-grey;
  }

  &__header {
    display: none;

    &__displayname {
      @include h3;
      & {
        margin-bottom: 1.25rem;
      }
    }

    &__gallery-description {
      display: flex;
      margin-bottom: 1.25rem;
      color: $summer-grey;
    }

    &__description {
      margin-bottom: 1.25rem;
      color: $summer-grey;
    }
    @media screen and (min-width: $md) {
      display: contents;
      margin-bottom: 1.5rem;

      &__displayname {
        @include h3;
        & {
          margin-bottom: 1rem;
        }
      }

      &__category {
        @include h5;
        & {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  &__image {
    display: none;

    @media screen and (min-width: $md) {
      display: block;
      width: 100%;
      flex: 1 1 50%;
      padding-right: 2rem;
      margin-bottom: 0;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__info {
    @media screen and (min-width: $md) {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
    }
  }

  &__sub_info {
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.75rem;
    border: 1px solid $ra-light-grey;

    @media screen and (min-width: $md) {
      padding: 1.5rem;
      margin-bottom: 2rem;
      border-radius: 1rem;
    }

    .edition {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &__type-container {
        display: flex;
        flex-direction: column;
      }

      &__label {
        font-size: 0.7rem;
        color: $summer-grey;

        @media screen and (min-width: $md) {
          font-size: 0.8rem;
        }
      }

      &__type {
        font-size: 0.9rem;
        font-weight: bold;

        @media screen and (min-width: $md) {
          font-size: 1rem;
        }
      }

      &__price {
        font-size: 1.1rem;
        font-weight: bold;

        @media screen and (min-width: $md) {
          font-size: 1.2rem;
        }
      }
    }

    .availability {
      font-size: 0.7rem;
      color: $summer-grey;
      margin-top: 0.3rem;

      @media screen and (min-width: $md) {
        font-size: 0.8rem;
        margin-top: 0.5rem;
      }
    }
  }

  &__purchase {
    background-color: $ra-light-grey;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 15px;
    border: 1px solid $ra-light-grey;

    @media screen and (min-width: $md) {
      padding: 1.5rem;
      margin-bottom: 2rem;
      border-radius: 20px;
    }

    .artwork-explorer__cta {
      .secondary-cta {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &__inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }

        &__text {
          position: relative;

          &::after {
            right: 0.5rem;
          }
        }

        &__border-top,
        &__border-bottom {
          width: 100%;
        }
      }
    }

    .buy-button {
      width: 100%;
      padding: 0.75rem;
      background-color: $ra-black;
      color: $ra-white;
      border: none;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: 0.75rem;

      &:hover {
        background-color: #333;
      }

      @media screen and (min-width: $md) {
        padding: 1rem;
        font-size: 1rem;
        border-radius: 1rem;
      }
    }

    .payment-info {
      @include body;
      & {
        margin-top: 0.75rem;
        text-align: center;
        a {
          text-decoration-thickness: 1px;
          transition: text-decoration-thickness 0.2s ease;

          &:hover {
            text-decoration-thickness: 2px;
          }
        }
      }
    }

    .payment-methods {
      display: flex;
      justify-content: center;
      margin-top: 0.75rem;

      @media screen and (min-width: $md) {
        margin-top: 1rem;
      }
    }
  }

  &__details {
    margin-bottom: 1.5rem;
    font-size: 1rem;

    @media screen and (min-width: $md) {
      margin-bottom: 2rem;
    }

    &__heading {
      @include h5;
      & {
        margin-bottom: 0.3rem;
        font-weight: 700;
      }

      @media screen and (min-width: $md) {
        margin-bottom: 0.5rem;
      }
    }

    dl {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $ra-light-grey;
      margin: 0;
      padding: 0;
    }

    dt,
    dd {
      padding: 0.5rem 0;
      margin: 0;
    }

    dt {
      flex-basis: 30%;
      padding-right: 1rem;
      font-weight: 100;
      color: $summer-grey;
      font-size: 0.9rem;
    }

    dd {
      flex-basis: 70%;
      font-weight: 400;
      color: $ra-black;

      @media screen and (min-width: $md) {
        font-size: 1rem;
      }
    }
  }

  &__purchase-info {
    &__heading {
      @include h5;
      & {
        margin-bottom: 0.3rem;
        font-weight: 700;
      }
      @media screen and (min-width: $md) {
        margin-bottom: 0.5rem;
      }
    }

    .accordion-wrapper {
      margin-top: 1rem;

      &__container {
        padding: 0;
      }

      &__grid {
        @media screen and (min-width: $md) {
          display: block;
        }
      }

      &__accordion {
        @media screen and (min-width: $md) {
          grid-column: 1 / span 12;
        }
      }

      &__title {
        @include h5;
        & {
          margin-bottom: 0.8rem;
          font-weight: 700;
        }
        @media screen and (min-width: $md) {
          margin-bottom: 0.5rem;
        }
      }

      .accordion {
        padding-top: 0.9rem;
        border-bottom: 1px solid $ra-light-grey;

        @media screen and (min-width: $md) {
          padding-top: 0.7rem;
        }

        &:first-child {
          padding-top: 0.75rem;
          border-top: 1px solid $ra-light-grey;

          @media screen and (min-width: $md) {
            padding-top: 1rem;
          }
        }

        &__label {
          @include body;
          & {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.artwork-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.artwork-option {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:nth-child(1):not(.selected) {
    border: 1px solid $ra-black;
  }

  &:nth-child(2):not(.selected) {
    background-color: $ra-white;
    border: 1px solid $ra-black;
  }

  &:hover:not(.selected):not(.sold-out) {
    background-color: darken($ra-light-blue, 5%);
  }

  &.selected {
    background-color: $ra-light-blue;
  }

  &.sold-out {
    background-color: $ra-white;
    border: 1px solid $ra-light-grey;
    cursor: default;
    opacity: 1;

    .artwork-option__content {
      padding-left: 0;
    }
    .artwork-option__label,
    .artwork-option__price,
    .artwork-option__type {
      color: $summer-grey;
    }

    &:hover {
      background-color: $ra-white;
    }
  }

  &__sold-tag {
    display: inline-block;
    color: $ra-red;
    padding: 0.25rem 0rem;
    border-radius: 0.25rem;
    font-weight: 600;

    .circle {
      width: 12px;
      height: 12px;
      background: $ra-red;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__label,
  &__price {
    font-weight: bold;
    font-size: 1.1em;
  }

  &__type {
    display: block;
    margin-bottom: 5px;
  }

  &__availability {
    display: block;
    color: $ra-red;
    font-size: 0.9em;
  }

  &__control {
    padding-right: 1rem;

    input {
      appearance: none;
      background-color: $ra-white;
      border: 1px solid $ra-blue;
      border-radius: 50%;
      margin: 0;
      width: 1.5rem;
      height: 1.5rem;
      box-sizing: border-box;

      &:checked {
        border-width: 0.4rem;
      }
    }

    &__label {
      border-radius: 1.25rem;

      @media screen and (min-width: $md) {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;

  @media screen and (min-width: $md) {
    margin-bottom: 0.5rem;
  }

  .label {
    @include body;

    &-center {
      font-weight: bold;
    }
  }

  .value {
    font-weight: 400;
    @include body;
  }

  .deposit-value {
    font-weight: bold;
  }

  h3 {
    font-weight: 700;
  }
}
