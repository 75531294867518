@import '../../lib.scss';

.explorer-hero {
  @media screen and (min-width: $sm) {
    margin-top: 1rem;
  }
  @media screen and (min-width: $xl) {
    margin-top: 2rem;
  }

  &__container {
    @include container;
  }

  &__promo {
    position: relative;

    &__image {
      z-index: 0;
      aspect-ratio: 1/1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-left: -1rem;
      width: calc(100% + 2rem);

      @media screen and (min-width: $sm) {
        aspect-ratio: 16/9;
        margin-left: 0;
        width: 100%;
      }
    }

    &__content {
      padding: 1rem;
      margin-top: -1rem;
      margin-right: -1rem;
      z-index: 1;
      position: relative;
      cursor: pointer;

      @media screen and (min-width: $sm) {
        margin-top: 0;
        position: absolute;
        width: calc(40% + 2rem);
        right: -2rem;
        bottom: -7rem;
        padding-right: 3rem;
      }

      @media screen and (min-width: $md) {
        padding: 2rem 3rem 2rem 2rem;
        width: calc(40% + 2rem);
        bottom: -3rem;
      }

      @media screen and (min-width: $lg) {
        width: calc(40% + 5rem);
        right: -5rem;
        padding-right: 6rem;
      }
    }

    &__title {
      @include h2;
    }

    &__subtitle {
      @include h5;
    }

    &__subtext {
      @include body;
      & {
        margin-top: 0.5rem;
      }
    }

    .primary-cta {
      margin-top: 1rem;
    }
  }

  &__browse {
    margin-top: -18rem;
    padding-top: 18rem;
    margin-left: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @media screen and (min-width: $sm) {
      margin-top: -4rem;
      padding-top: 4rem;
      padding-left: 2rem;
      margin-left: -2rem;
    }

    @media screen and (min-width: $lg) {
      padding-left: 5rem;
      margin-left: -5rem;
    }

    &__grid {
      padding: 1rem 0;
      @media screen and (min-width: $sm) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 3rem;
        padding: 2rem 0;
      }
    }

    &__title {
      @include h2;
      & {
        grid-column: 1 / span 7;
        font-family: $intro-font;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        margin-top: 0;
      }

      svg {
        max-width: 100%;
        height: auto;
        vertical-align: top;
      }
    }

    &__sponsors {
      grid-column: 1 / span 12;

      @media screen and (min-width: $sm) {
        grid-column: 8 / span 4;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: 2rem 0 0 1rem;
      }
    }

    &__sponsor {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__title {
        @include body;
        & {
          display: block;
          margin-bottom: 0.25rem;
        }
      }

      &__link {
        display: block;
      }

      &__logo {
        max-width: 70%;
        height: auto;

        @media screen and (max-width: $sm) {
          aspect-ratio: 16/9;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  &.bg--ra-light-blue {
    .tertiary-cta,
    .tertiary-cta:hover {
      color: $ra-black;

      svg {
        fill: $ra-black;
      }

      path,
      line {
        stroke: $ra-black;
      }
    }
  }
}
