@import '../../lib.scss';
.storybook-icons-wrapper {
  display: flex;
  align-items: center;
  .icon {
    margin: 1rem;
  }
}

.icon {
  &--underline {
    max-height: 4px;
    min-height: 4px;
    max-width: 90%;
    min-width: 90%;
    margin: 0 auto;
  }
  &--animate-spinner .ra-spinner {
    transform-origin: center;
    animation: spinner_ra 0.75s step-end infinite;
  }
  @keyframes spinner_ra {
    8.3% {
      transform: rotate(30deg);
    }
    16.6% {
      transform: rotate(60deg);
    }
    25% {
      transform: rotate(90deg);
    }
    33.3% {
      transform: rotate(120deg);
    }
    41.6% {
      transform: rotate(150deg);
    }
    50% {
      transform: rotate(180deg);
    }
    58.3% {
      transform: rotate(210deg);
    }
    66.6% {
      transform: rotate(240deg);
    }
    75% {
      transform: rotate(270deg);
    }
    83.3% {
      transform: rotate(300deg);
    }
    91.6% {
      transform: rotate(330deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
