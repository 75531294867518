@import '../../../lib.scss';

.explorer-basket-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;

  @media screen and (min-width: $sm) {
    align-items: flex-start;
    padding: 0;
  }
}

.explorer-basket-popup {
  @include container;
  & {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 33rem;
    background: $ra-white;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $sm) {
      width: 38rem;
      height: 26rem;
      margin-top: 4.5rem;
      padding: 0;
    }
  }
  &__title {
    @include h5;
    & {
      position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 1rem;
      padding: 0 3rem 0 1rem;
    }
    @media screen and (min-width: $sm) {
      position: absolute;
      width: 25rem;
      height: 4rem;
      left: 4rem;
      top: 3.25rem;
      padding: 0;
    }
  }

  &__description {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: $sm) {
      position: absolute;
      width: 25rem;
      height: 4rem;
      left: 4rem;
      top: 7rem;
      padding: 0;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    @media screen and (min-width: $sm) {
      left: 4rem;
      right: 4rem;
      bottom: 2rem;
      padding: 0;
    }
  }

  &__confirm-button {
    color: $ra-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cancel-link {
    line-height: 1.5rem;
    text-decoration-line: underline;
    color: $ra-blue;
  }

  &__close-button {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    right: 0.5rem;
    top: 0.5rem;
    background: $ra-black;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @media screen and (min-width: $sm) {
      width: 3rem;
      height: 2.94rem;
      right: 1rem;
      top: 1rem;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 1.2rem;
      height: 0.125rem;
      background: $ra-white;

      @media screen and (min-width: $sm) {
        width: 1.428rem;
      }
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.75rem 1rem;
    gap: 1rem;
    position: relative;
    margin: 1rem 0;
    border-top: 1px solid $ra-light-grey;
    border-bottom: 1px solid $ra-light-grey;

    @media screen and (min-width: $sm) {
      position: absolute;
      left: 4rem;
      top: 12.5rem;
      padding: 0.75rem 0;
      margin: 0;
      width: calc(100% - 8rem);
    }
  }

  &__item-image {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: $sm) {
      width: 4.375rem;
      height: 4.5rem;
    }
  }

  &__item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;

    @media screen and (min-width: $sm) {
      width: 21.8125rem;
    }
  }

  &__item-cat-no {
    @include small;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
    color: $ra-black;
  }

  &__item-title {
    @include body;
    & {
      color: $ra-black;
      font-weight: bold;
    }
  }
  &__item-subtitle {
    @include small;
    text-transform: uppercase;
    color: $summer-grey;
  }
}

body.popup-open {
  overflow: hidden;
}
