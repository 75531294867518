@import '../../lib.scss';

.inline-image {
  &--container {
    @include container;
    & {
      margin-top: 5rem;
    }
    @media screen and (min-width: $md) {
      @include grid;
    }
  }
  &__figure--grid {
    @media screen and (min-width: $md) {
      grid-column: 1 / span 8;
    }
  }
  &__fig-caption {
    margin-top: 0.5rem;
    &__title {
      @include small;
      font-weight: $bold;
      padding-bottom: 0.5rem;
      display: block;
    }
  }
}
