@import '../../lib.scss';

.date-picker {
  &__container {
    // overflow: hidden;
    padding-top: 0.625rem;
    padding: 1rem 0;
  }
  &__year-month-wrap {
    display: grid;
    grid-template-areas: 'leftArrow monthYear rightArrow';
    grid-template-columns: 40px 1fr 40px;
    margin-bottom: 1rem;
  }
  &__month-year {
    grid-area: monthYear;
    text-align: center;
    font-weight: bold;
    color: $ra-black;
  }
  &__right-arrow,
  &__left-arrow {
    position: relative;
    grid-area: rightArrow;
    background-color: transparent;
    padding: 0;
    line-height: 1rem;
    svg {
      height: 22px;
      width: 22px;
    }
  }
  &__left-arrow {
    grid-area: leftArrow;
  }
  &__days-grid,
  &__day-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  &__days-grid {
    margin-bottom: 0.5rem;
  }
  &__days {
    font-size: 14px;
    text-align: center;
    color: $ra-black;
    font-weight: bold;
  }
  &__date {
    position: relative;
    z-index: 1;
    line-height: 32px;
    height: 32px;
    display: inline-block;
    width: 32px;
  }
  &__day {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: $ra-black;
    background-color: transparent;
    line-height: 32px;
    height: 32px;
    // width: 32px;
    text-align: center;
    padding: 0;
    &[disabled],
    &--disabled {
      opacity: 0.5;
      cursor: default;
    }
    &--today {
      .date-picker__date {
        background-color: $ra-white;
        color: $ra-black;
        border-radius: 1rem;
      }
    }
    &:nth-child(7n + 1) {
      border-radius: 1rem 0 0 1rem;
      &::before {
        display: none;
      }
    }
    &:nth-child(7n) {
      border-radius: 0 1rem 1rem 0;
    }

    &--highlight {
      position: relative;
      background: $ra-white;
      .date-picker__date {
        background-color: $ra-white;
        border-radius: 1rem;
        z-index: 1;
      }

      + .date-picker__day--highlight {
        .date-picker__date {
          background-color: $ra-white;
          color: $ra-black;
        }

        &::before {
          content: '';
          background: $ra-white;
          position: absolute;
          width: 100%;
          left: -50%;
          height: 100%;
          z-index: 0;
        }
      }
      &.date-picker__day--highlight--last,
      &.date-picker__day--highlight--first {
        background: transparent;
        .date-picker__date {
          background-color: $ra-blue;
          color: $ra-white;
        }
      }
    }

    &--hide {
      visibility: hidden;
      + .date-picker__day--highlight {
        &::before {
          display: none;
        }
      }
    }
    &--screen-reader {
      @include sr-only;
    }
  }
}
