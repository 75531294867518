@import '../../../lib.scss';

.filters {
  padding: 2rem 0;
  @media screen and (min-width: $md) {
    padding: 2.7rem 0 1.25rem 0;
  }
  &__container {
    @include container();
  }
  &__grid {
    @media screen and (min-width: $md) {
      @include grid(12, 1fr);
    }
  }
  .filters__grid {
    gap: 1rem;
  }
  &__title {
    grid-column: 1 / span 8;
    grid-row: 1;
    @include h1;
  }
  &__checkboxes {
    grid-column: 8 / span 5;
    grid-row: 1;
    justify-self: self-end;
    display: flex;
    .checkbox {
      margin: 1rem 2rem 1rem 0;
    }
  }
  &__filters {
    margin: 1rem 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    grid-row: 2;
    grid-column: 1 / span 12;
    border-radius: 1.25rem;
    .filter-dropdown {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .filter-dropdown + .filter-dropdown {
      margin-top: 1rem;
      @media screen and (min-width: $md) {
        margin-top: 0.5rem;
      }
    }

    @media screen and (min-width: $md) {
      margin: 0;
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
      .filter-dropdown {
        width: 33.333%;
        &:first-child {
          margin-top: 0.5rem;
        }
        &:last-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &__selected-filters {
    button {
      text-transform: capitalize;
    }
    @media screen and (min-width: $md) {
      grid-row: 3;
      grid-column: 1 / span 10;
      min-height: 3rem;
    }
  }
  &__clear-filters {
    @media screen and (min-width: $md) {
      grid-row: 3;
      grid-column: 10 / span 3;
      text-align: right;
      margin-right: 1rem;
    }
  }
}
