@import '../../lib.scss';

.explorer-artworks {
  &__container {
    @include container;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 2rem 0;
    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: $md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (min-width: $lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
