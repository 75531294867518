@import '../../../lib.scss';

.review-card {
  display: inherit;
  height: 100%;
  border-bottom: 2px solid $ra-light-grey;
  padding-bottom: 1rem;
  &__rating-list {
    display: flex;
    padding-bottom: 0.5rem;
  }
  &__rating {
    margin-right: 0.5rem;
  }
  &__quote {
    font-weight: $bold;
  }
  &__cite {
    @include h6;
    & {
      margin: 0.5rem 0;
    }
  }
  &__link {
    @include linkInline;
    &:visited {
      color: $ra-blue;
    }
    &:focus,
    &:focus-visible {
      border-bottom: none;
      box-shadow: none;
      text-decoration: none;
      @include linkFocus;
    }
  }
}
