@import './lib.scss';

@media print {
  body {
    padding: 0 !important;
  }
  .header,
  .footer {
    display: none !important;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  @media screen and (min-width: $sm) {
    font-size: 18px;
  }
}

body {
  @include body;
  & {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

button {
  @include body;
  & {
    border: 0;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    background-color: transparent;
  }
}

a {
  color: inherit;
  outline: none;
}

button,
a {
  color: inherit;
  cursor: pointer;
}

blockquote {
  margin: 0;
}

input {
  @include body;
}

figure {
  margin: 0;
}

.sr-only {
  @include sr-only;
}

.link-hotspot {
  @include linkHotspot;
}

::selection {
  background: $ra-yellow;
}
::-moz-selection {
  background: $ra-yellow;
}

/*************************
** Theme **
*************************/

h1 em,
h2 em,
h3 em,
h4 em,
h5 em,
h6 em {
  font-style: normal;
}

.theme--ra-blue {
  background-color: $ra-blue;
  color: $ra-white;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-light-blue;
  }
}

.theme--ra-light-blue {
  background-color: $ra-light-blue;
  color: $ra-black;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-blue;
  }
}

.theme--ra-green {
  background-color: $ra-green;
  color: $ra-white;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-light-green;
  }
}

.theme--ra-light-green {
  background-color: $ra-light-green;
  color: $ra-black;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-green;
  }
}

.theme--ra-purple {
  background-color: $ra-purple;
  color: $ra-white;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-light-purple;
  }
}

.theme--ra-light-purple {
  background-color: $ra-light-purple;
  color: $ra-black;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-purple;
  }
}

.theme--ra-pink {
  background-color: $ra-pink;
  color: $ra-white;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-light-pink;
  }
}

.theme--ra-light-pink {
  background-color: $ra-light-pink;
  color: $ra-black;
  h1 em,
  h2 em,
  h3 em,
  h4 em,
  h5 em,
  h6 em {
    color: $ra-pink;
  }
}

.theme--ra-black {
  background-color: $ra-black;
  color: $ra-white;
}

.theme--ra-white {
  background-color: $ra-white;
  color: $ra-black;
}

.theme--ra-light-grey {
  background-color: $ra-light-grey;
  color: $ra-black;
}

.no-scroll {
  overflow: hidden;
}

.onward-links:last-child,
.half-half:last-child,
.donate:last-child {
  margin-bottom: -5rem;
}

// This is required to offset the header when used in conjunction with the jump links so the section doesn't scroll under the navigation
.ra--offset {
  scroll-margin-top: 8rem;
}
