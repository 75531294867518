@import '../../lib.scss';

.big-link {
  margin-top: 5rem;
  padding: 5rem 0;
  position: relative;
  text-align: center;
  &__container {
    @include container;
  }
  &__title {
    @include h2;
  }
  &__list {
    @include h5;
    &__item {
      margin: 1rem 0;
    }
    a {
      @include linkInline;
      &:focus-visible {
        @include linkFocus;
      }
    }
  }
}
