@import '../../lib.scss';

.donate {
  margin-top: 5rem;
  &__container {
    @media screen and (min-width: $md) {
      @include container;
    }
  }

  &__grid {
    @media screen and (min-width: $md) {
      @include grid(12, 1fr);
      grid-template-rows: 160px 1fr;
    }
  }

  &__image {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
    z-index: 1;
    margin-right: 1rem;
    aspect-ratio: 16/9;
    @media screen and (min-width: $md) {
      grid-row: 2 / 3;
      z-index: 1;
      grid-column: 7 / span 6;
      margin: auto -2rem 0 0;
    }
    @media screen and (min-width: $lg) {
      margin: auto -5rem 0 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    grid-row: 2 / 4;
    padding: 2rem 1rem;
    padding-top: calc(46% + 2rem);
    margin-top: -46%;
    @media screen and (min-width: $md) {
      @include grid;
      grid-column: 1 / span 8;
      grid-row: 1 / 3;
      padding: 2rem 1rem;
      grid-template-areas: 'content content content content content content content content content content . . . ';
      padding: 3rem 2rem;
      margin: 0;
      margin-left: -2rem;
    }
    @media screen and (min-width: $lg) {
      padding: 3rem 5rem;
      margin-left: -5rem;
    }
  }
  &__inner {
    grid-area: content;
  }

  &__title {
    @include h2;
  }
  &__description {
    @include body-text-sans-serif;
    margin: 1rem 0;
  }
  &__amounts {
    text-align: center;
    @media screen and (min-width: 30rem) {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (min-width: 30rem) and (max-width: $md) {
      justify-content: center;
    }
    @media screen and (min-width: $lg) {
      flex-wrap: nowrap;
    }
  }

  &__amount {
    &__item {
      @media screen and (min-width: $sm) {
        margin-right: 1rem;
      }
    }
  }

  &__about {
    margin: 1rem 0;
    @include body-text-sans-serif;
  }

  &__other {
    margin-top: 1rem;

    &__button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;
      @media screen and (min-width: $sm) {
        justify-content: flex-end;
        margin-top: 0;
        grid-area: button;
      }
    }
    &__field {
      display: grid;
      grid-area: field;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
        'field'
        'button';
      gap: 1rem;
      .form-field__field {
        position: relative;
        &::before {
          content: '£';
          position: absolute;
          left: -20px;
          display: block;
          font-weight: bold;
          top: 10px;
        }
      }
    }
    @media screen and (max-width: $sm) {
      .form-field--inline-half {
        width: 100%;
      }
    }
  }
  &__error-message {
    display: flex;
    justify-content: flex-end;
  }
}
