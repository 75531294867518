@import '../../lib.scss';

.hero-carousel {
  &__container {
    position: relative;
    @include container;
  }

  &__grid {
    position: relative;

    @include grid(4, 1fr);
    @media screen and (min-width: $sm) {
      @include grid(12, 1fr);
    }
  }

  &__viewport {
    overflow: hidden;
    position: relative;
    z-index: 0;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: calc(100% + 2rem);
    grid-column: 1/-1;
    margin-left: -1rem;
    margin-right: -1rem;
    max-height: 590px;
    @media screen and (min-width: $sm) {
      aspect-ratio: 126/59;
      margin-right: 0rem;
      margin-left: -2rem;
    }

    @media screen and (min-width: $md) {
      width: calc(100% + 1.5rem);
      margin-left: -1.5rem;
      margin-top: 0.5rem;
    }

    @media screen and (min-width: $lg) {
      width: 100%;
      margin-left: -4rem;
      margin-top: 1rem;
    }
  }

  &__wrapper {
    display: flex;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__slide {
    position: relative;
    min-width: 100%;
    opacity: 0.25;
    transition: opacity ease 0.25s;

    &--active {
      opacity: 1;
    }
  }

  &__image-wrapper {
    position: relative;
    z-index: 0;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    max-height: 590px;
    @media screen and (min-width: $sm) {
      aspect-ratio: 126/59;
      margin-left: 0;
      width: 100%;
      grid-column: 1/-1;
    }

    & img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__pause-wrapper {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 1.5rem;
    right: 0.5rem;
    @media screen and (min-width: $sm) {
      bottom: 3rem;
      right: 1.5rem;
    }

    @media screen and (min-width: $md) {
      bottom: 4.5rem;
    }
  }

  &__pause-btn {
    position: relative;
    background-color: rgba($ra-white, 0.7);
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon--play {
      margin-left: 4px;
    }
    &:hover {
      cursor: pointer;
      background-color: $ra-white;
    }

    &:focus-visible {
      @include buttonFocus;
    }
  }

  &__pause-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .hero-caption__sidebar {
    @media screen and (min-width: $md) {
      top: 0.5rem;
    }
    @media screen and (min-width: $lg) {
      top: 1rem;
    }
  }
}
