@import '../../../lib.scss';

.primary-navigation {
  padding: 1rem 0;

  &__subnav {
    &__container {
      height: 100%;
    }
    &__grid {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .event-card {
    &__title {
      @include h5;
    }

    &__subtitle {
      @include body;
    }

    &__label,
    &__free,
    &__time {
      display: none;
    }
  }

  @media screen and (max-width: ($md - 1px)) {
    padding: 1rem 0;
    &__link {
      > button {
        background-color: transparent;
        color: $ra-white;
        display: block;
        @include h5;
        font-weight: bold;
        text-decoration: none;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &:hover {
          text-decoration: underline;
        }
        svg {
          right: 0;
        }
      }
    }

    &__subnav {
      position: fixed;
      left: 200vw;
      height: calc(100% - $mobile-nav-height);
      width: 100%;
      z-index: 8;
      top: $mobile-nav-height;
      transition: left 0.2s ease-in-out;
      &--open {
        left: 0;
      }
      &__back {
        button {
          background: $ra-black;
          width: 100%;
          color: $ra-white;
          padding: 2rem 3rem;
          @include h5;
          text-decoration: none;
          display: flex;
          align-items: center;
          svg {
            margin-right: 1rem;
          }
        }
      }
      &__links {
        padding: 1rem;
        flex-grow: 1;
        overflow-y: auto;
      }
      &__link {
        > a {
          padding: 1rem 2rem;
          display: block;
          @include h5;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media screen and (min-width: $md) {
    padding: 0;
    width: 100%;
    &__container {
      display: flex;
      justify-content: space-between;
      margin-left: 8rem;
    }
    &__links {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-self: flex-end;
      padding-bottom: 1.5rem;
    }
    &__link {
      display: inline-block;
      margin-right: 2.5rem;
      &:last-of-type {
        margin-right: 0;
      }
      > button {
        background-color: transparent;
        color: $ra-white;
        @include h6;
        font-size: 1.1rem;
        display: inline-block;
        @include underline-link;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        &:focus-visible {
          @include linkFocus;
          &:hover {
            border-bottom: none;
            color: $ra-black;
          }
        }
        &:hover {
          .icon--underline {
            display: block;
          }
          .icon--chevron-right {
            display: none;
          }
        }
        .icon--underline {
          bottom: -0.5rem;
        }
      }

      &--open {
        & > button,
        & > button:focus {
          svg {
            display: block;
          }
        }
      }
    }

    &__subnav {
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 26rem;
      padding: 2rem 0;
      box-sizing: border-box;
      margin-top: 28px;
      z-index: 7;
      height: 100%;
      overflow-y: auto;
      &__container {
        @include container();
        margin: 0;
      }
      &__grid {
        @include grid(12, 1fr);
      }
      &__links {
        grid-column: 1 / span 8;
        margin-left: 6rem;
      }
      &__overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(42, 42, 42, 0.4);
        top: $desktop-nav-height;
        left: 0;
        z-index: -1;
      }
      .event-card {
        grid-column: 10 / span 3;
        border-bottom: 0;
        &__link:hover {
          border-bottom: 4px solid $ra-black;
          color: $ra-black;
        }
      }
      &__link {
        > a {
          @include h6;
          font-size: 1.1rem;
          display: inline-block;
          font-weight: bold;
          text-decoration: none;
          border-bottom: 4px solid transparent;
          padding-top: 0.5rem;
          &:hover {
            color: $ra-black;
            border-bottom: 4px solid $ra-black;
          }

          &:focus-visible {
            @include linkFocus;
            &:hover {
              color: $ra-black;
            }
          }
        }
      }
    }
    &__subnav {
      display: none;
      &--open {
        display: block;
      }
    }
  }
  &__friend {
    display: none;
    text-align: right;
    margin: 0.5rem 1rem 0.25rem 0;
    @media screen and (min-width: $md) {
      display: block;
    }
  }
  @media screen and (min-width: $lg) {
    &__container {
      margin-left: 10rem;
    }
    &__subnav {
      &__container {
        max-width: 100%;
      }
      &__links {
        margin-left: 5rem;
      }
    }
    &__link {
      margin-right: 5rem;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  @media screen and (min-width: $xl) {
    &__links {
      justify-content: center;
    }
    &__subnav {
      &__container {
        max-width: calc(1272px + 10rem);
        margin: 0 auto;
      }
      &__links {
        margin-left: 11rem;
      }
    }
    .event-card {
      position: relative;
      right: 15rem;
    }
  }
}
