@import '../../lib.scss';

.jump-links {
  position: sticky;
  top: 4.2rem;
  margin-top: 1.5rem;
  z-index: 2;
  background-color: $ra-white;
  @media screen and (min-width: $md) {
    top: 4.2rem;
  }
  &__container {
    @include container;
  }
  &__list {
    display: flex;
    flex-direction: column;
    border-top: 2px solid $ra-light-grey;
    border-bottom: 2px solid $ra-light-grey;
    overflow: hidden;
    max-height: 3.375rem;
    transition: max-height 0.3s ease;
    @media screen and (min-width: $md) {
      flex-flow: row wrap;
      border-top: 0;
      overflow: visible;
      max-height: initial;
      padding-left: 6rem;
    }
    @media screen and (min-width: $lg) {
      padding-left: 5rem;
    }

    &--open {
      max-height: 100vh;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .jump-links__item--toggle::before {
        transform: rotate(-180deg);
      }
    }
  }
  &__item {
    display: flex;
    padding: 1rem 2rem;
    @media screen and (min-width: $md) {
      padding: 1rem 0;
      margin-right: 3.5rem;
    }
    &--toggle {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 1rem;
        right: 2rem;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'%3E%3Cpath fill='%231D1D1B' d='m8.178 7.679-.747-.775c-.88-.914-1.72-1.785-2.461-2.56-1.489-1.545-2.606-2.687-2.937-3C.72.088.088.45.088.45-.283 1.38.644 2.66.644 2.66l2.362 2.495c.713.751 1.541 1.628 2.439 2.566.446.469.909.962 1.378 1.454.237.247.475.5.712.752l.504.535c.232.252.487.45.753.565.26.108.556.144.851.084.29-.066.527-.21.742-.427.22-.21.388-.535.562-.817l.29-.481c.352-.577.723-1.136 1.094-1.665a36.647 36.647 0 0 1 2.183-2.837c1.379-1.623 2.473-2.644 2.635-2.795.319-.306.527-.763.66-1.153.134-.385.192-.704.192-.704-.609-.408-1.32-.216-1.865.103-.545.318-.921.739-.921.739s-.232.222-.626.637a38.538 38.538 0 0 0-3.66 4.46c-.284.402-.567.829-.851 1.262-.278.408-.875 1.328-1.297.89l-.603-.644Z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform 0.3s ease;
      }
    }
  }

  &__toggle {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  &__toggle-label {
    flex: 1;
    cursor: pointer;
  }

  &__link {
    flex: 1;
  }

  &__link,
  &__toggle-label {
    @include h6;
    & {
      @include underline-link;
    }
    & {
      font-size: 1.1rem;
      display: inline-block;
      font-weight: bold;
      position: relative;
    }
  }
}

// FIXME: This is here because Safari cannot calculate the height correctly of collapsable elements whilst scrolling
.jump-links__hidden-list {
  display: none;
}
