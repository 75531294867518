@import '../../lib.scss';

.image-gallery {
  margin-top: 5rem;
  position: relative;

  &__top {
    @include container;
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    @include h2;
  }

  &__instruction {
    @include container;
    & {
      @include body;
    }
    & {
      margin-top: 1rem;
    }
  }

  &__nav {
    display: flex;
    padding: 0.75rem 0 0 1rem;
    @media screen and (min-width: $sm) {
      padding: 1rem 0 1rem 2rem;
    }
  }

  &__button {
    background: none;
    display: block;
    font-size: 0;
    line-height: 0;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    cursor: pointer;
    &:first-of-type {
      margin-right: 1rem;
    }
    &:not([disabled]) {
      background-color: $ra-black;
      &:hover {
        background-color: $ra-blue;
      }
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: $ra-white;
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
    @include buttonFocus;
  }

  &__embla {
    width: 100vw;
    overflow: hidden;
    padding: 0 1rem;
    margin-top: 1rem;
    @media screen and (min-width: $sm) {
      padding: 0 2rem;
    }
  }

  &__list {
    display: flex;
    > li {
      margin-right: 1rem;
    }
    @media screen and (min-width: $sm) {
      max-width: 1272px;
      margin: 0 auto;
      flex-wrap: nowrap;
      flex-direction: row;
      > li {
        margin-right: 2rem;
      }
    }

    &--restrict-height {
      .image-card__image .image {
        height: 460px;

        img {
          max-width: 100%;
          max-height: 100%;
          height: auto;
          width: auto;
        }
      }
    }
  }

  &__card {
    cursor: pointer;
    flex: 0 0 77%;
    @media screen and (min-width: $sm) {
      flex: 0 0 calc(33% - 1.33rem);
    }

    @media screen and (min-width: $lg) {
      flex: 0 0 calc(25% - 1.5rem);
    }
  }

  &__card-button {
    width: 100%;
    background: none;
    display: block;
    text-align: left;
    @include buttonFocus;

    &:hover {
      .image-card__title {
        border-bottom: 4px solid $ra-blue;
        color: $ra-blue;
      }
    }
  }

  &__cta {
    @include container;
    @media screen and (min-width: $sm) {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // z-index needs to be higher than the header's z-index of 9
    z-index: 10;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $ra-white;
  }
}
