@import '../../lib.scss';

.whats-on {
  @include container;
  & {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: $md) {
    margin-top: 5rem;
  }
}
